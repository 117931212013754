import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import { isEqual } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import { useEditSocietyConfigMutation, useGetSocietyConfigQuery } from "../../../redux/reducers/settings.reducer";
import { ISocietyConfigDetails } from "../../../types";
import { useAppDispatch } from "../../../redux";
import { LoadingButton } from "@mui/lab";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import AddFlatDialog from "../SocietyConfiguration/AddFlatDialog";

interface ISelectedCustomFieldInputs {
	name: string;
	type: "dropdown" | "custom";
	entries: string[];
	new_field: boolean;
}

type ICustomFieldInputErrors = {
	[key in keyof Omit<ISelectedCustomFieldInputs, "entries" | "new_field">]: string;
};

const NEW_FIELD_NAME = "New Custom Field";

const CUSTOM_FIELDS_LIMIT = 5;
const FIELD_TYPES_LIMIT = 50;

const CustomFields: FC = () => {
	const dispatch = useAppDispatch();

	const customFieldsListRef = useRef<HTMLDivElement>(null);

	// APIS
	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery();
	const societyDetails: ISocietyConfigDetails | null =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	const [
		editSociety,
		{
			isLoading: editSocietyLoading,
			isSuccess: editSocietySuccess,
			error: editSocietyError,
			reset: resetEditSocietyState
		}
	] = useEditSocietyConfigMutation();

	const [customFieldsList, setCustomFieldsList] = useState<ISelectedCustomFieldInputs[]>([]);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [selectedEntriesIndex, setSelectedEntriesIndex] = useState<number[]>([]);
	const [entriesSelectionEnabled, setEntriesSelectionEnabled] = useState<boolean>(false);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<"single" | "all" | null>(null);
	const [showAddEntryDialog, setShowAddEntryDialog] = useState<boolean>(false);
	const [selectedCustomFieldInputs, setSelectedCustomFieldInputs] = useState<ISelectedCustomFieldInputs>({
		name: "",
		type: "dropdown",
		entries: [],
		new_field: false
	});
	const [inputErrors, setInputErrors] = useState<ICustomFieldInputErrors>({
		name: "",
		type: ""
	});

	const initialCustomFieldsList = useMemo<ISelectedCustomFieldInputs[]>(() => {
		if (!societyDetails) return [];
		if (!societyDetails.custom_fields) return [];

		return societyDetails.custom_fields.map((item) => ({
			name: item.name,
			type: item.type,
			entries: [...item.entries],
			new_field: false
		}));
	}, [societyDetails]);

	const initialSelectedCustomFieldInputs = useMemo<ISelectedCustomFieldInputs>(() => {
		const customFieldDetails = initialCustomFieldsList[selectedIndex];

		if (customFieldDetails) {
			return customFieldDetails;
		}

		return {
			name: "",
			type: "dropdown",
			entries: [],
			new_field: false
		};
	}, [initialCustomFieldsList, selectedIndex]);

	const IsSaveButtonDisabled = useMemo<boolean>(() => {
		const selectedCustomFieldDetails = customFieldsList[selectedIndex];

		if (!selectedCustomFieldDetails) return true;
		if (entriesSelectionEnabled) return true;
		if (!selectedCustomFieldInputs.name || !selectedCustomFieldInputs.type) return true;

		if (selectedCustomFieldDetails.new_field && selectedCustomFieldInputs.name === NEW_FIELD_NAME) return true;

		if (isEqual(selectedCustomFieldInputs, initialSelectedCustomFieldInputs)) return true;

		return false;
	}, [
		customFieldsList,
		entriesSelectionEnabled,
		initialSelectedCustomFieldInputs,
		selectedCustomFieldInputs,
		selectedIndex
	]);

	const IsPreviewFunctionButtonDisabled = useMemo<boolean>(() => {
		if (customFieldsList.length <= 0) return true;
		if (selectedCustomFieldInputs.type === "custom") return true;

		return false;
	}, [customFieldsList.length, selectedCustomFieldInputs.type]);

	function handleOpenAddEntryDialog() {
		setShowAddEntryDialog(true);
	}

	function handleCloseAddEntryDialog() {
		setShowAddEntryDialog(false);
	}

	function handleOpenDeleteConfirmationDialog(type: "single" | "all") {
		setShowDeleteConfirmationDialog(type);
	}

	function handleCloseDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(null);
	}

	function handleSubmitDeleteCustomFields() {
		if (showDeleteConfirmationDialog === "single") {
			if (selectedIndex >= 0) {
				const updatedCustomFieldsList = [...customFieldsList];
				updatedCustomFieldsList.splice(selectedIndex, 1);
				setCustomFieldsList(updatedCustomFieldsList);

				setSelectedIndex((currentIndex) => (currentIndex > 0 ? currentIndex - 1 : 0));

				if (societyDetails) {
					editSociety({
						id: societyDetails.id,
						data: {
							custom_fields: updatedCustomFieldsList.map((item) => ({
								name: item.name,
								type: item.type,
								entries: item.entries
							}))
						}
					});
				}
			}
		} else if (showDeleteConfirmationDialog === "all") {
			setCustomFieldsList([]);
			setSelectedIndex(0);

			if (societyDetails) {
				editSociety({
					id: societyDetails.id,
					data: {
						custom_fields: []
					}
				});
			}
		}

		handleCloseDeleteConfirmationDialog();
	}

	function handleScrollCustomFieldsList(scrollTo: "left" | "right") {
		if (!customFieldsListRef.current) return;

		const scrollValue = scrollTo === "left" ? -300 : scrollTo === "right" ? 300 : 0;
		customFieldsListRef.current.scrollTo({ left: scrollValue, behavior: "smooth" });
	}

	function handleChangeSelectedCustomField(index: number) {
		setSelectedIndex(index);
		setSelectedEntriesIndex([]);
	}

	function handleAddButtonClick() {
		const updatedCustomFieldsList = [...customFieldsList];

		const updatedLength = updatedCustomFieldsList.push({
			name: NEW_FIELD_NAME,
			type: "dropdown",
			entries: [],
			new_field: true
		});

		setCustomFieldsList(updatedCustomFieldsList);
		setSelectedIndex(updatedLength - 1);
	}

	function handleEnableEntriesSelection() {
		setEntriesSelectionEnabled(true);
	}

	function handleDisableEntriesSelection() {
		setEntriesSelectionEnabled(false);
		setSelectedEntriesIndex([]);
	}

	function handleChangeSelectedCustomFieldName(value: string) {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			name: value
		}));
	}

	function handleChangeSelectedCustomFieldType(value: "dropdown" | "custom") {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			type: value
		}));

		handleDisableEntriesSelection();
	}

	function handleToggleEntrySelection(index: number) {
		setSelectedEntriesIndex((currentEntries) => {
			if (currentEntries.includes(index)) {
				return currentEntries.filter((item) => item !== index);
			}

			const updatedEntries = [...currentEntries];
			updatedEntries.push(index);
			return updatedEntries;
		});
	}

	function handleSaveButtonClick() {
		const updatedInputErrors: ICustomFieldInputErrors = {
			name: "",
			type: ""
		};

		if (!selectedCustomFieldInputs.name) {
			updatedInputErrors.name = "Custom field name is required";
		}

		if (!selectedCustomFieldInputs.type) {
			updatedInputErrors.type = "Type of custom field input is required";
		}

		setInputErrors(updatedInputErrors);

		if (updatedInputErrors.name || updatedInputErrors.type) {
			return;
		}

		if (selectedIndex >= 0) {
			const updatedCustomEntriesList = [...customFieldsList];

			updatedCustomEntriesList[selectedIndex] = {
				name: selectedCustomFieldInputs.name,
				type: selectedCustomFieldInputs.type,
				entries: selectedCustomFieldInputs.entries,
				new_field: false
			};

			setCustomFieldsList(updatedCustomEntriesList);

			// TODO: SUBMIT API CALL
			if (societyDetails) {
				editSociety({
					id: societyDetails.id,
					data: {
						custom_fields: updatedCustomEntriesList.map((item) => ({
							name: item.name,
							type: item.type,
							entries: item.entries
						}))
					}
				});
			}
		}
	}

	function handleRemoveButtonClick() {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			entries: currentInputs.entries.filter((_, index) => !selectedEntriesIndex.includes(index))
		}));

		handleDisableEntriesSelection();
	}

	function handleSubmitAddEntry(entry: string) {
		const updatedInputs = { ...selectedCustomFieldInputs };

		if (updatedInputs.entries.includes(entry)) {
			dispatch(
				showFeedbackNotification({
					message: "The entered field type already exists",
					severity: "warning"
				})
			);

			return;
		}

		updatedInputs.entries.push(entry);
		setSelectedCustomFieldInputs(updatedInputs);

		handleCloseAddEntryDialog();
	}

	useEffect(() => {
		if (societyDetails && societyDetails.custom_fields) {
			setCustomFieldsList(
				societyDetails.custom_fields.map((item) => ({
					name: item.name,
					type: item.type,
					entries: [...item.entries],
					new_field: false
				}))
			);
		}
	}, [societyDetails]);

	useEffect(() => {
		if (customFieldsList.length > 0 && selectedIndex >= 0) {
			const customFieldDetails = customFieldsList[selectedIndex];
			if (customFieldDetails) {
				setSelectedCustomFieldInputs(customFieldDetails);
			}
		}
	}, [customFieldsList, selectedIndex]);

	useEffect(() => {
		if (editSocietySuccess) {
			resetEditSocietyState();
			refetchSocietyConfig();
			dispatch(
				showFeedbackNotification({
					message: "Custom fields updated successfully",
					severity: "success"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietySuccess]);

	useEffect(() => {
		if (editSocietyError && "data" in editSocietyError) {
			dispatch(
				showFeedbackNotification({
					message: editSocietyError.data ? String(editSocietyError.data) : "Could not update custom fields",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyError]);

	return (
		<Box className="society-configuration-screen-wrapper">
			<Box className="building-configuration-component-wrapper">
				<Box className="buildings-list-wrapper">
					<Box className="buildings-list-scroll-arrow left" onClick={() => handleScrollCustomFieldsList("left")}>
						<ArrowLeftIcon />
					</Box>

					<Box className="buildings-list" ref={customFieldsListRef}>
						<Box
							sx={{
								overflowX: "auto",
								msOverflowStyle: "none",
								scrollbarWidth: "none",
								display: "flex",
								flexWrap: "nowrap",
								gap: "0.75rem"
							}}
						>
							{customFieldsList.map((customFieldItem, index) => (
								<Box className="buildings-list-item" key={index}>
									<Button
										disableElevation
										size="small"
										variant="contained"
										color="primary"
										startIcon={<ApartmentIcon />}
										sx={{ textTransform: "none" }}
										classes={{ containedPrimary: selectedIndex !== index ? "inactive" : "" }}
										onClick={() => handleChangeSelectedCustomField(index)}
									>
										{customFieldItem.name}
									</Button>
								</Box>
							))}
						</Box>

						{customFieldsList.length < CUSTOM_FIELDS_LIMIT ? (
							<Box className="buildings-list-item">
								<Button
									size="small"
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									onClick={handleAddButtonClick}
									sx={{ textTransform: "capitalize" }}
									disabled={customFieldsList.some((item) => item.name === NEW_FIELD_NAME || item.new_field)}
								>
									Add New Custom Field
								</Button>
							</Box>
						) : null}
					</Box>

					<Box className="buildings-list-scroll-arrow right" onClick={() => handleScrollCustomFieldsList("right")}>
						<ArrowRightIcon />
					</Box>
				</Box>

				<Box className="building-inputs-wrapper">
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<InputLabel
								htmlFor="custom-field-name-input"
								sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
							>
								Custom Field Name
							</InputLabel>

							<TextField
								fullWidth
								size="small"
								variant="filled"
								id="custom-field-name-input"
								placeholder="Add field name"
								value={selectedCustomFieldInputs.name}
								onChange={(event) => handleChangeSelectedCustomFieldName(event.target.value)}
								error={!!inputErrors.name}
								helperText={inputErrors.name}
								InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
								inputProps={{ style: { textTransform: "capitalize" } }}
								onFocus={(event) => {
									if (event.target.value === NEW_FIELD_NAME) event.target.select();
								}}
								disabled={customFieldsList.length <= 0}
							/>
						</Grid>

						<Grid item xs={12} md={3}>
							<InputLabel
								htmlFor="type-input"
								sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
							>
								Type of Custom Field Input
							</InputLabel>

							<FormControl
								fullWidth
								size="small"
								variant="filled"
								error={!!inputErrors.type}
								hiddenLabel
								id="type-input"
								disabled={customFieldsList.length <= 0}
							>
								<Select
									fullWidth
									placeholder="Add type of custom field"
									value={selectedCustomFieldInputs.type}
									onChange={(event) => handleChangeSelectedCustomFieldType(event.target.value as "dropdown" | "custom")}
									disableUnderline
									classes={{ root: "building-input-root" }}
								>
									<MenuItem value="dropdown">Multiple Choice</MenuItem>
									<MenuItem value="custom">Text Input</MenuItem>
								</Select>

								{inputErrors.type ? <FormHelperText>{inputErrors.type}</FormHelperText> : null}
							</FormControl>
						</Grid>
					</Grid>
				</Box>

				<Typography variant="h6" color="var(--color-primary-dark)">
					Preview of Custom Field
				</Typography>

				<Box className="building-config-actions-wrapper">
					<Button
						variant="outlined"
						color="success"
						size="small"
						startIcon={<AddCircleIcon />}
						onClick={handleOpenAddEntryDialog}
						disabled={
							entriesSelectionEnabled ||
							IsPreviewFunctionButtonDisabled ||
							selectedCustomFieldInputs.entries.length >= FIELD_TYPES_LIMIT
						}
					>
						Add Field Type
					</Button>

					{entriesSelectionEnabled ? (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							startIcon={<CloseIcon />}
							onClick={handleDisableEntriesSelection}
							sx={{ minWidth: "100px" }}
							disabled={IsPreviewFunctionButtonDisabled}
						>
							Cancel
						</Button>
					) : (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							startIcon={<CheckBoxOutlineBlankIcon />}
							onClick={handleEnableEntriesSelection}
							sx={{ minWidth: "100px" }}
							disabled={IsPreviewFunctionButtonDisabled}
						>
							Select
						</Button>
					)}

					{entriesSelectionEnabled ? (
						<Button
							variant="outlined"
							color="error"
							size="small"
							startIcon={<DeleteOutlineIcon />}
							disabled={selectedEntriesIndex.length <= 0 || IsPreviewFunctionButtonDisabled}
							onClick={handleRemoveButtonClick}
						>
							Remove
						</Button>
					) : null}
				</Box>

				<Box className="buildings-flats-preview-wrapper">
					<Box className="buildings-flats-preview">
						{selectedCustomFieldInputs.entries.map((item, index) => (
							<Box
								key={index}
								className={`building-flats-preview-item ${IsPreviewFunctionButtonDisabled ? "inactive" : ""}`}
							>
								{entriesSelectionEnabled ? (
									<Box className="icon-wrapper" onClick={() => handleToggleEntrySelection(index)}>
										{selectedEntriesIndex.includes(index) ? (
											<CheckBoxIcon fontSize="inherit" />
										) : (
											<CheckBoxOutlineBlankIcon fontSize="inherit" />
										)}
									</Box>
								) : null}

								<Typography color="inherit" variant="body2" textAlign="center">
									{item}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
					<Button
						variant="outlined"
						color="error"
						startIcon={<DeleteOutlineIcon />}
						disabled={customFieldsList.length <= 0}
						onClick={() => handleOpenDeleteConfirmationDialog("all")}
					>
						Delete All
					</Button>

					{customFieldsList.length > 1 ? (
						<Button
							variant="outlined"
							color="error"
							startIcon={<DeleteOutlineIcon />}
							onClick={() => handleOpenDeleteConfirmationDialog("single")}
						>
							Delete
						</Button>
					) : null}

					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						startIcon={<SaveIcon />}
						sx={{ paddingX: 3 }}
						onClick={handleSaveButtonClick}
						disabled={IsSaveButtonDisabled}
						loading={editSocietyLoading}
						loadingPosition="start"
					>
						Save
					</LoadingButton>
				</Box>

				<ConfirmationDialog
					open={!!showDeleteConfirmationDialog}
					title={
						showDeleteConfirmationDialog === "single"
							? "Delete Custom Field"
							: showDeleteConfirmationDialog === "all"
							? "Delete Custom Fields"
							: ""
					}
					heading={
						showDeleteConfirmationDialog === "single"
							? "Are you sure you want to delete this custom field?"
							: showDeleteConfirmationDialog === "all"
							? "Are you sue you want to delete all the custom fields?"
							: ""
					}
					onClose={handleCloseDeleteConfirmationDialog}
					onConfirm={handleSubmitDeleteCustomFields}
					width="sm"
					color="error"
				/>

				<AddFlatDialog
					open={showAddEntryDialog}
					allowChars
					title="Add Field Type"
					inputLabel="Field Type"
					onClose={handleCloseAddEntryDialog}
					onSubmit={handleSubmitAddEntry}
				/>
			</Box>
		</Box>
	);
};

export default CustomFields;
