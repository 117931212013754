import {
	Box,
	Button,
	ButtonOwnProps,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	TextField,
	Typography
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import "./confirmationDialog.css";
import { LoadingButton } from "@mui/lab";

interface IConfirmationDialogProps {
	open: boolean;
	title: string;
	heading: string;
	warning?: string;
	confirmationType?: "input" | "button";
	color?: ButtonOwnProps["color"];
	confirmButtonLoading?: boolean;
	width?: DialogProps["maxWidth"];
	onClose: () => void;
	onConfirm: () => void;
}

const CONFIRMATION_TEXT = "confirm";

const ConfirmationDialog: FC<IConfirmationDialogProps> = (props) => {
	const { open, title, heading, warning, confirmationType, color, confirmButtonLoading, width, onClose, onConfirm } =
		props;

	const [confirmationInput, setConfirmationInput] = useState<string>("");

	function handleChangeConfirmationInput(event: ChangeEvent<HTMLInputElement>) {
		setConfirmationInput(event.target.value);
	}

	function handleConfirmationFormSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		onConfirm();
	}

	useEffect(() => {
		if (open) setConfirmationInput("");
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth={width ? width : "md"} TransitionProps={{ exit: false }}>
			<DialogTitle textAlign="center" color="white" classes={{ root: `confirmation-dialog-title-root ${color}` }}>
				{title}
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "confirmation-dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleConfirmationFormSubmit}>
					<Typography variant="h6" textAlign="center">
						{heading}
					</Typography>

					{warning ? (
						<Box className="confirmation-info-section">
							<InfoIcon color="inherit" fontSize="inherit" />

							<Typography textAlign="center" color="inherit">
								{warning}
							</Typography>
						</Box>
					) : null}

					{confirmationType === "input" ? (
						<Typography textAlign="center" color="#474747">
							Please type 'confirm' below before proceeding
						</Typography>
					) : null}

					{confirmationType === "input" ? (
						<Box className="confirmation-input-section-wrapper">
							<Box className="confirmation-input-wrapper">
								<TextField
									fullWidth
									autoFocus={!navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)}
									variant="standard"
									color="secondary"
									placeholder="Please type confirm here"
									inputProps={{ style: { textAlign: "center" } }}
									value={confirmationInput}
									onChange={handleChangeConfirmationInput}
									autoComplete="off"
								/>
							</Box>
						</Box>
					) : null}

					<Box className="confirmation-dialog-actions-wrapper">
						<Button variant="outlined" color="error" onClick={onClose}>
							Cancel
						</Button>

						<LoadingButton
							disableElevation
							variant="contained"
							color={color ? color : "primary"}
							type="submit"
							disabled={confirmationType === "input" && confirmationInput.toLowerCase() !== CONFIRMATION_TEXT}
							loading={confirmButtonLoading}
						>
							Proceed
						</LoadingButton>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

ConfirmationDialog.defaultProps = { confirmationType: "button" };

export default ConfirmationDialog;
