import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useState } from "react";
import { ICustomTimeRangeDetails } from ".";
import {
	DateRange,
	StaticDateRangePicker,
	TimePicker,
	dateRangeCalendarClasses,
	pickersLayoutClasses,
	renderTimeViewClock
} from "@mui/x-date-pickers-pro";
import { TIME_RANGE_OPTIONS_E } from "../../types";

interface ICustomRangeSelectionDialogProps {
	open: boolean;
	defaultValues?: ICustomTimeRangeDetails;
	showPredefinedValues?: boolean;
	defaultPredefinedValue?: TIME_RANGE_OPTIONS_E;
	onClose: () => void;
	onSubmit: (updatedValue: ICustomTimeRangeDetails, timeRange: TIME_RANGE_OPTIONS_E) => void;
}

interface IPredefinedFilterDetails {
	key: TIME_RANGE_OPTIONS_E;
	name: string;
}

const PREDEFINED_FILTERS_LIST: IPredefinedFilterDetails[] = [
	{ key: TIME_RANGE_OPTIONS_E.TODAY, name: "Live / Today" },
	{ key: TIME_RANGE_OPTIONS_E.YESTERDAY, name: "Yesterday" },
	{ key: TIME_RANGE_OPTIONS_E.WEEK, name: "Last 7 Days" },
	{ key: TIME_RANGE_OPTIONS_E.MONTH, name: "Last Month" },
	{ key: TIME_RANGE_OPTIONS_E.QUARTER, name: "Last 3 Months" },
	{ key: TIME_RANGE_OPTIONS_E.HALF_YEAR, name: "Last 6 Months" }
];

const CustomRangeSelectionDialog: FC<ICustomRangeSelectionDialogProps> = (props) => {
	const { open, defaultValues, showPredefinedValues, defaultPredefinedValue, onClose, onSubmit } = props;

	const [predefinedRange, setPredefinedRange] = useState<TIME_RANGE_OPTIONS_E>(TIME_RANGE_OPTIONS_E.TODAY);
	const [customRange, setCustomRange] = useState<ICustomTimeRangeDetails>({
		from: moment().startOf("day"),
		to: moment().endOf("day")
	});

	function handleChangeDateRangeValues(updatedValue: DateRange<moment.Moment>) {
		setPredefinedRange(TIME_RANGE_OPTIONS_E.CUSTOM);

		setCustomRange((currentCustomRangeState) => {
			if (updatedValue[0] && updatedValue[1]) {
				const from = currentCustomRangeState.from
					.set("date", updatedValue[0].get("date"))
					.set("month", updatedValue[0].get("month"))
					.set("year", updatedValue[0].get("year"));

				const to = currentCustomRangeState.to
					.set("date", updatedValue[1].get("date"))
					.set("month", updatedValue[1].get("month"))
					.set("year", updatedValue[1].get("year"));

				return { from, to };
			}

			return currentCustomRangeState;
		});
	}

	function handleChangeTimeRangeValues(value: Moment, key: "from" | "to") {
		setPredefinedRange(TIME_RANGE_OPTIONS_E.CUSTOM);

		setCustomRange((currentCustomRangeState) => {
			const updatedValue = currentCustomRangeState[key]
				.set("hours", value.get("hours"))
				.set("minutes", value.get("minutes"));

			return {
				...currentCustomRangeState,
				[key]: updatedValue
			};
		});
	}

	function handleApplyButtonClick() {
		onSubmit(customRange, predefinedRange);
	}

	function handlePredefinedDateValueSelection(updatedValue: TIME_RANGE_OPTIONS_E) {
		switch (updatedValue) {
			case TIME_RANGE_OPTIONS_E.TODAY:
				setCustomRange({
					from: moment().startOf("day"),
					to: moment().endOf("day")
				});
				break;

			case TIME_RANGE_OPTIONS_E.YESTERDAY:
				setCustomRange({
					from: moment().subtract(1, "day").startOf("day"),
					to: moment().subtract(1, "day").endOf("day")
				});
				break;

			case TIME_RANGE_OPTIONS_E.WEEK:
				setCustomRange({
					from: moment().subtract(1, "week").startOf("day"),
					to: moment().endOf("day")
				});
				break;

			case TIME_RANGE_OPTIONS_E.MONTH:
				setCustomRange({
					from: moment().subtract(1, "month").startOf("day"),
					to: moment().endOf("day")
				});
				break;

			case TIME_RANGE_OPTIONS_E.QUARTER:
				setCustomRange({
					from: moment().subtract(3, "months").startOf("day"),
					to: moment().endOf("day")
				});
				break;

			case TIME_RANGE_OPTIONS_E.HALF_YEAR:
				setCustomRange({
					from: moment().subtract(6, "months").startOf("day"),
					to: moment().endOf("day")
				});
				break;

			default:
				break;
		}

		setPredefinedRange(updatedValue);
	}

	useEffect(() => {
		if (open) {
			if (defaultValues) setCustomRange(defaultValues);
			if (defaultPredefinedValue) setPredefinedRange(defaultPredefinedValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const startDateString = customRange.from.format("DD/MM/YYYY");
	const endDateString = customRange.to.format("DD/MM/YYYY");

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogContent classes={{ root: "dialog-content-root" }}>
				{showPredefinedValues ? (
					<Box className="predefined-date-value-selection-wrapper">
						{PREDEFINED_FILTERS_LIST.map((predefinedFilterItem) => (
							<Box
								key={predefinedFilterItem.key}
								className={`predefined-date-value ${predefinedRange === predefinedFilterItem.key ? "active" : ""}`}
								onClick={() => handlePredefinedDateValueSelection(predefinedFilterItem.key)}
							>
								<Typography>{predefinedFilterItem.name}</Typography>
							</Box>
						))}
					</Box>
				) : null}

				<Typography variant="h6" marginTop="1rem">
					Please select start and end date
				</Typography>

				<Box className="date-range-toolbar-section-wrapper">
					<Box className="date-range-inputs-wrapper">
						<TextField label="From" value={startDateString} size="small" fullWidth />
						<TextField label="To" value={endDateString} size="small" fullWidth />
					</Box>
				</Box>

				<Typography variant="h6">Please select start and end time</Typography>

				<Box className="date-range-toolbar-section-wrapper">
					<Box className="date-range-inputs-wrapper">
						<TimePicker
							label="From"
							value={customRange.from}
							onChange={(value) => handleChangeTimeRangeValues(value ?? moment().startOf("day"), "from")}
							closeOnSelect={false}
							viewRenderers={{
								hours: renderTimeViewClock,
								minutes: renderTimeViewClock
							}}
							slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
						/>

						<TimePicker
							label="To"
							value={customRange.to}
							onChange={(value) => handleChangeTimeRangeValues(value ?? moment().endOf("day"), "to")}
							closeOnSelect={false}
							viewRenderers={{
								hours: renderTimeViewClock,
								minutes: renderTimeViewClock
							}}
							slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
						/>
					</Box>
				</Box>

				<StaticDateRangePicker
					disableFuture
					disableHighlightToday
					disableAutoMonthSwitching
					calendars={1}
					value={[customRange.from, customRange.to]}
					onChange={handleChangeDateRangeValues}
					sx={{
						[`.${pickersLayoutClasses.contentWrapper}`]: {
							alignItems: "center"
						},
						[`.${dateRangeCalendarClasses.monthContainer}`]: {
							border: "1px solid #00000099",
							borderRadius: "4px"
						}
					}}
					slots={{
						toolbar: () => <></>,
						actionBar: () => <></>
					}}
				/>

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
					<Button variant="contained" color="primary" sx={{ paddingX: 3 }} onClick={handleApplyButtonClick}>
						Apply
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default CustomRangeSelectionDialog;
