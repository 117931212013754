import axios from "axios";
import {
	IGetSecureLoginOTPResponseData,
	ILoggedInUserDetails,
	ILoginUserRequestData,
	IVerifyOTPRequestData,
	IVerifyOTPResponseData
} from "../../types";
import { BASE_API_URL, getAPIHeaderOptions } from "../../utils/apiUtils";

const handleLoginUser = (requestData: ILoginUserRequestData): Promise<ILoggedInUserDetails> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/login`, requestData, { headers: getAPIHeaderOptions() })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				let errorMessage = "Something went wrong";

				if (error && error.response && error.response.data) {
					const errorValue = Object.values(error.response.data)[0];
					errorMessage = errorValue ? String(errorValue) : "Something went wrong";
				}

				reject(errorMessage);
			});
	});
};

const handleLogoutUser = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/logout`, {}, { headers: getAPIHeaderOptions(true) })
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error?.response?.data?.detail);
			});
	});
};

const handleCheckLoginToken = (): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_API_URL}/analytics/ownertype`, { headers: getAPIHeaderOptions(true) })
			.then((response) => {
				if (response.status === 200) resolve();
				else reject(response.status);
			})
			.catch((error) => {
				reject(error?.response?.status);
			});
	});
};

const handleGetSecureLoginOTP = (): Promise<IGetSecureLoginOTPResponseData> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/switch-secure-login`, { method: "email" }, { headers: getAPIHeaderOptions(true) })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				let errorMessage = "Something went wrong";

				if (error && error.response && error.response.data) {
					const errorValue = Object.values(error.response.data)[0];
					errorMessage = errorValue ? String(errorValue) : "Something went wrong";
				}

				reject(errorMessage);
			});
	});
};

const handleVerifyOTP = (requestData: IVerifyOTPRequestData): Promise<IVerifyOTPResponseData> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${BASE_API_URL}/auth/logged-otp-verification`, requestData, { headers: getAPIHeaderOptions(true) })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				let errorMessage = "Something went wrong";

				if (error && error.response && error.response.data) {
					const errorValue = Object.values(error.response.data)[0];
					errorMessage = errorValue ? String(errorValue) : "Something went wrong";
				}

				reject(errorMessage);
			});
	});
};

const authServices = {
	handleLoginUser,
	handleLogoutUser,
	handleCheckLoginToken,
	handleGetSecureLoginOTP,
	handleVerifyOTP
};

export default authServices;
