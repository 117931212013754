import { getAPIHeaderOptions } from "../../utils/apiUtils";
import {
	IGetEventLogsRequestData,
	IGetEventLogsResponseData,
	IGetUserSettingsResponseData,
	IUpdateUserSettingsRequestData
} from "../../types";
import { queriesApi } from "./queries.reducer";

const eventLogs = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET VEHICLE EVENT LOGS
		getEventLogs: builder.query<IGetEventLogsResponseData, IGetEventLogsRequestData>({
			query: (requestData) => ({
				url: "/analytics/numberplatevent",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			}),
			keepUnusedDataFor: 0
		}),

		// GET USER SETTINGS
		getUserSettings: builder.query<IGetUserSettingsResponseData, string>({
			query: (username) => ({
				url: `/auth/users/${username}`,
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// UPDATE USER SETTINGS
		updateUserSettings: builder.mutation<unknown, IUpdateUserSettingsRequestData>({
			query: (requestData) => ({
				url: `/auth/users/${requestData.username}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			})
		})
	})
});

export const {
	// GET VEHICLE EVENT LOGS
	useGetEventLogsQuery,
	// GET USER SETTINGS
	useGetUserSettingsQuery,
	// UPDATE USER SETTINGS
	useUpdateUserSettingsMutation
} = eventLogs;
