import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./deviceSelection.css";
import { useGetDevicesListQuery } from "../../redux/reducers/settings.reducer";
import { IDeviceDetails } from "../../types";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface IDeviceSelectionProps {
	colorMode?: "light" | "dark";
	disableAllSelected?: boolean;
	defaultValue?: number;
	onChange: (deviceId: number) => void;
}

const DeviceSelection: FC<IDeviceSelectionProps> = (props) => {
	const { colorMode, disableAllSelected, defaultValue, onChange } = props;

	const dropdownAnchorElementRef = useRef<HTMLDivElement>(null);

	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<IDeviceDetails | null>(null);

	// APIS
	const { data: getDevicesListResponse } = useGetDevicesListQuery({});
	const devicesList = useMemo(() => getDevicesListResponse?.results ?? [], [getDevicesListResponse]);

	function handleOpenDropdown() {
		if (devicesList && devicesList.length > 1) setShowDropdown(true);
	}

	function handleCloseDropdown() {
		setShowDropdown(false);
	}

	function handleChangeSelectedDevice(deviceDetails: IDeviceDetails | null) {
		setSelectedDeviceDetails(deviceDetails);
		handleCloseDropdown();
		onChange(deviceDetails ? deviceDetails.id : -1);
	}

	useEffect(() => {
		if (disableAllSelected && devicesList.length > 0) {
			const defaultDeviceDetails = devicesList[0];
			setSelectedDeviceDetails(defaultDeviceDetails);
			onChange(defaultDeviceDetails.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disableAllSelected, devicesList]);

	useEffect(() => {
		if (typeof defaultValue === "number" && devicesList.length > 0) {
			const selectedDeviceDetails = devicesList.find((deviceItem) => deviceItem.id === defaultValue);
			if (selectedDeviceDetails) {
				setSelectedDeviceDetails(selectedDeviceDetails);
				// onChange(defaultValue);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue, devicesList]);

	return (
		<>
			<Box
				component="div"
				className={`device-selection-button ${colorMode}`}
				ref={dropdownAnchorElementRef}
				aria-haspopup="true"
				id="device-selection-dropdown-button"
				onClick={handleOpenDropdown}
				aria-expanded={showDropdown}
				aria-controls="device-selection-dropdown-menu"
			>
				<Box className="device-selection-button-text">
					<DeveloperBoardIcon color="inherit" fontSize="small" />
					<Typography fontWeight={500}>
						{selectedDeviceDetails ? selectedDeviceDetails.device_name : "All Devices"}
					</Typography>
				</Box>

				{devicesList && devicesList.length > 1 ? (
					showDropdown ? (
						<ArrowDropUpIcon color="inherit" fontSize="small" />
					) : (
						<ArrowDropDownIcon color="inherit" fontSize="small" />
					)
				) : null}
			</Box>

			{devicesList && devicesList.length > 1 ? (
				<Menu
					disableAutoFocusItem
					id="device-selection-dropdown-menu"
					anchorEl={dropdownAnchorElementRef.current}
					open={showDropdown}
					onClose={handleCloseDropdown}
					MenuListProps={{ disablePadding: true, "aria-labelledby": "device-selection-dropdown-button" }}
					slotProps={{ paper: { elevation: 1 } }}
				>
					{devicesList.map((deviceItem) =>
						selectedDeviceDetails && selectedDeviceDetails.id === deviceItem.id ? null : (
							<MenuItem
								divider
								key={deviceItem.id}
								onClick={() => handleChangeSelectedDevice(deviceItem)}
								classes={{ root: "device-selection-dropdown-menu-item" }}
							>
								{deviceItem.device_name}
							</MenuItem>
						)
					)}

					{!disableAllSelected && selectedDeviceDetails ? (
						<MenuItem
							onClick={() => handleChangeSelectedDevice(null)}
							classes={{ root: "device-selection-dropdown-menu-item" }}
						>
							All Devices
						</MenuItem>
					) : null}
				</Menu>
			) : null}
		</>
	);
};

DeviceSelection.defaultProps = {
	colorMode: "light"
};

export default DeviceSelection;
