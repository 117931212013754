import CryptoJS from "crypto-js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
	IAuthReducerState,
	ILoggedInUserDetails,
	ILoginUserRequestData,
	IVerifyOTPRequestData,
	IVerifyOTPResponseData
} from "../../types";
import authServices from "../services/auth.services";

const encryptionKey = String(process.env.REACT_APP_ENCRYPTION_KEY);

const initialState: IAuthReducerState = {
	loading: false,
	success: false,
	error: "",
	currentAction: "",
	unauthorizedError: false,
	user: {
		username: "",
		role: 0,
		token: "",
		full_name: "",
		mode: 0,
		email: "",
		login_type: "normal",
		secure_login_enabled_user: false,
		secure_login_enabled_society: false
	}
};

export const loginUser = createAsyncThunk<ILoggedInUserDetails, ILoginUserRequestData>(
	"auth/loginUser",
	authServices.handleLoginUser
);

export const logoutUser = createAsyncThunk<void>("auth/logoutUser", authServices.handleLogoutUser);

export const verifyOTP = createAsyncThunk<IVerifyOTPResponseData, IVerifyOTPRequestData>(
	"auth/verifyOTP",
	authServices.handleVerifyOTP
);

const authReducer = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		clearUnauthState: (state) => {
			state.unauthorizedError = false;
			state.user.token = "";
		},
		clearAuthState: (state) => {
			state.unauthorizedError = false;
			state.user = {
				username: "",
				token: "",
				role: 0,
				full_name: "",
				mode: 0,
				email: "",
				login_type: "normal",
				secure_login_enabled_user: false,
				secure_login_enabled_society: false
			};
		}
	},
	extraReducers: (builder) => {
		// LOGIN USER
		builder.addCase(loginUser.pending, (state) => {
			state.loading = true;
			state.success = false;
			state.error = "";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
		});

		builder.addCase(loginUser.rejected, (state, { error }) => {
			state.loading = false;
			state.success = false;
			state.error = error.message ?? "Something went wrong";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
			state.user = {
				token: "",
				username: "",
				role: 0,
				full_name: "",
				mode: 0,
				email: "",
				login_type: "normal",
				secure_login_enabled_user: false,
				secure_login_enabled_society: false
			};
		});

		builder.addCase(loginUser.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.success = true;
			state.error = "";
			state.currentAction = "auth/loginUser";
			state.unauthorizedError = false;
			state.user = { ...payload, token: CryptoJS.AES.encrypt(payload.token, encryptionKey).toString() };
		});

		// LOGOUT USER
		builder.addCase(logoutUser.pending, (state) => {
			state.loading = true;
			state.success = false;
			state.error = "";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
		});

		builder.addCase(logoutUser.rejected, (state, { error }) => {
			state.loading = false;
			state.success = false;
			state.error = error.message ?? "Something went wrong";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
		});

		builder.addCase(logoutUser.fulfilled, (state) => {
			state.loading = false;
			state.success = true;
			state.error = "";
			state.currentAction = "auth/logoutUser";
			state.unauthorizedError = false;
			state.user = {
				token: "",
				username: "",
				role: 0,
				full_name: "",
				mode: 0,
				email: "",
				login_type: "normal",
				secure_login_enabled_user: false,
				secure_login_enabled_society: false
			};
		});

		// VERIFY OTP
		builder.addCase(verifyOTP.pending, (state) => {
			state.loading = true;
			state.success = false;
			state.error = "";
			state.currentAction = "auth/verifyOTP";
			state.unauthorizedError = false;
		});

		builder.addCase(verifyOTP.rejected, (state, { error }) => {
			state.loading = false;
			state.success = false;
			state.error = error.message ?? "Something went wrong";
			state.currentAction = "auth/verifyOTP";
			state.unauthorizedError = false;
		});

		builder.addCase(verifyOTP.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.success = true;
			state.error = "";
			state.currentAction = "auth/verifyOTP";
			state.unauthorizedError = false;
			state.user = {
				...state.user,
				full_name: payload.full_name,
				mode: payload.mode,
				role: payload.role,
				token: CryptoJS.AES.encrypt(payload.token, encryptionKey).toString(),
				username: payload.username,
				login_type: payload.login_type
			};
		});
	}
});

export const { clearAuthState, clearUnauthState } = authReducer.actions;

export default authReducer;
