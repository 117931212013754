import { createSlice } from "@reduxjs/toolkit";
import { ISecureLoginDialogReducerState } from "../../types";

const initialState: ISecureLoginDialogReducerState = {
	show: false
};

const secureLoginDialogReducer = createSlice({
	name: "secureLoginDialog",
	initialState: initialState,
	reducers: {
		openSecureLoginDialog: (state) => {
			state.show = true;
		},
		closeSecureLoginDialog: (state) => {
			state.show = false;
		}
	}
});

export const { openSecureLoginDialog, closeSecureLoginDialog } = secureLoginDialogReducer.actions;

export default secureLoginDialogReducer;
