import {
	Box,
	Dialog,
	DialogContent,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	Link,
	Radio,
	RadioGroup,
	RadioProps,
	styled,
	TextField,
	Typography
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./secureLoginDialog.css";
import NumberInput from "../NumberInput";
import { useAppDispatch, useAppSelector } from "../../redux";
import authServices from "../../redux/services/auth.services";
import { LoadingButton } from "@mui/lab";
import { verifyOTP } from "../../redux/reducers/auth.reducer";

interface ISecureLoginDialogProps {
	open: boolean;
	onClose: () => void;
}

enum RENDER_CONTENT_E {
	EMAIL_SELECTION,
	OTP_LOGIN
}

type ContentRendererType = {
	[key in RENDER_CONTENT_E]: ReactNode;
};

const EmailRadioIcon = styled("span")({
	borderRadius: "50%",
	width: 18,
	height: 18,
	border: "2px solid white",
	position: "relative"
});

const EmailRadioCheckedIcon = styled(EmailRadioIcon)({
	"&::before": {
		content: '""',
		backgroundColor: "white",
		borderRadius: "50%",
		position: "absolute",
		top: 2,
		right: 2,
		bottom: 2,
		left: 2
	}
});

const EmailRadio = (props: RadioProps) => {
	return <Radio checkedIcon={<EmailRadioCheckedIcon />} icon={<EmailRadioIcon />} {...props} />;
};

const SecureLoginDialog: FC<ISecureLoginDialogProps> = (props) => {
	const { open, onClose } = props;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const [renderContent, setRenderContent] = useState<RENDER_CONTENT_E>(RENDER_CONTENT_E.EMAIL_SELECTION);
	const [selectedEmail, setSelectedEmail] = useState<string>(authState.user.email);
	const [secureLoginError, setSecureLoginError] = useState<string>("");
	const [remainingTime, setRemainingTime] = useState(0);
	const [sendOTPLoading, setSendOTPLoading] = useState<boolean>(false);
	const [secureLoginTransactionId, setSecureLoginTransactionId] = useState<string>("");
	const [otpInput, setOtpInput] = useState<string>("");

	const emailsList = useMemo(() => {
		if (authState.user.email) {
			return [authState.user.email];
		}

		return [];
	}, [authState.user.email]);

	const Timer = useMemo(() => {
		const hours = Math.floor(remainingTime / 3600);
		const minutes = Math.floor((remainingTime % 3600) / 60);
		const seconds = remainingTime % 60;

		let timerString = `${`00${minutes}`.slice(-2)}:${`00${seconds}`.slice(-2)}`;

		if (hours > 0) {
			timerString = `${`00${hours}`.slice(-2)}:${timerString}`;
		}

		return timerString;
	}, [remainingTime]);

	function startTimer(duration: number) {
		setRemainingTime(duration);

		timerRef.current = setInterval(() => {
			setRemainingTime((currentTime) => {
				if (currentTime <= 0) {
					if (timerRef.current) clearInterval(timerRef.current);
					return 0;
				}

				return currentTime - 1;
			});
		}, 1000);
	}

	function handleChangeSelectedEmail(event: ChangeEvent<HTMLInputElement>) {
		setSelectedEmail(event.target.value);
		setSecureLoginError("");
	}

	function handleChangeOTPInput(event: ChangeEvent<HTMLInputElement>) {
		setOtpInput(event.target.value);
	}

	function handleGetOTPButtonClick() {
		if (!selectedEmail) {
			setSecureLoginError("Please select an email address");
			return;
		}

		setSendOTPLoading(true);

		authServices
			.handleGetSecureLoginOTP()
			.then((response) => {
				if (response && response.transaction_id) {
					setSecureLoginTransactionId(response.transaction_id);
					setRenderContent(RENDER_CONTENT_E.OTP_LOGIN);
					startTimer(response.resend_otp_seconds ?? 2 * 60); // Start timer for 2 minutes
					setSecureLoginError("");
				} else {
					setSecureLoginError("Failed to send OTP. Please try again later.");
				}
			})
			.catch((error) => {
				setSecureLoginError(error);
			})
			.finally(() => {
				setSendOTPLoading(false);
			});
	}

	const handleClose = useCallback(() => {
		onClose();
		setRenderContent(RENDER_CONTENT_E.EMAIL_SELECTION);
		setSelectedEmail("");
		setSecureLoginError("");
		setRemainingTime(0);
	}, [onClose]);

	function handleVerifyOTPButtonClick(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (secureLoginTransactionId && otpInput) {
			dispatch(
				verifyOTP({
					transaction_id: secureLoginTransactionId,
					otp: otpInput
				})
			);
		}
	}

	useEffect(() => {
		if (open) {
			setSelectedEmail(authState.user.email);
		} else if (timerRef.current) {
			clearInterval(timerRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (authState.currentAction === "auth/verifyOTP" && authState.success) {
			handleClose();
		}
	}, [authState.currentAction, authState.success, handleClose]);

	useEffect(() => {
		if (authState.currentAction === "auth/verifyOTP" && authState.error) {
			setSecureLoginError(authState.error);
		}
	}, [authState.currentAction, authState.error]);

	const ContentRenderer: ContentRendererType = {
		[RENDER_CONTENT_E.EMAIL_SELECTION]: (
			<Box className="secure-login-dialog-content">
				<Box className="login-email-selection-wrapper">
					<FormControl>
						<RadioGroup value={selectedEmail} onChange={handleChangeSelectedEmail}>
							{emailsList.map((emailItem) => (
								<FormControlLabel
									key={emailItem}
									value={emailItem}
									control={<EmailRadio />}
									label={emailItem}
									classes={{ label: "secure-email-select-label" }}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Box>

				{secureLoginError ? (
					<Box className="secure-login-error-wrapper">
						<Typography variant="body2">{secureLoginError}</Typography>
					</Box>
				) : null}

				<LoadingButton
					fullWidth
					disableElevation
					variant="contained"
					color="success"
					onClick={handleGetOTPButtonClick}
					sx={{ textTransform: "capitalize" }}
					loading={sendOTPLoading}
				>
					Send OTP
				</LoadingButton>
			</Box>
		),
		[RENDER_CONTENT_E.OTP_LOGIN]: (
			<Box className="secure-login-dialog-content" component="form" noValidate onSubmit={handleVerifyOTPButtonClick}>
				<Box className="login-email-selection-wrapper">
					<Box className="secure-login-input-wrapper">
						<InputLabel htmlFor="email-input" sx={{ fontWeight: 500, color: "white", marginBottom: "0.5rem" }}>
							Email ID
						</InputLabel>

						<TextField
							required
							fullWidth
							variant="filled"
							id="email-input"
							placeholder="Enter email ID"
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "secure-login-input-root" } }}
							value={selectedEmail}
							disabled
						/>
					</Box>

					<Box className="secure-login-input-wrapper">
						<InputLabel htmlFor="otp-input" sx={{ fontWeight: 500, color: "white", marginBottom: "0.5rem" }}>
							OTP
						</InputLabel>

						<NumberInput
							required
							password
							fullWidth
							autoFocus
							positiveOnly
							disableSigned
							disableDecimal
							maxLength={6}
							variant="filled"
							id="otp-input"
							placeholder="Enter the OTP"
							value={otpInput}
							onChange={handleChangeOTPInput}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "secure-login-input-root" } }}
						/>
					</Box>
				</Box>

				{secureLoginError ? (
					<Box className="secure-login-error-wrapper">
						<Typography variant="body2">{secureLoginError}</Typography>
					</Box>
				) : null}

				<Box className="secure-login-actions-wrapper">
					<LoadingButton
						fullWidth
						disableElevation
						variant="contained"
						color="success"
						type="submit"
						sx={{ textTransform: "capitalize" }}
						loading={authState.currentAction === "auth/verifyOTP" && authState.loading}
					>
						Login Securely
					</LoadingButton>

					{remainingTime > 0 ? (
						<Typography fontSize="14px" color="#ffffff">
							{Timer}
						</Typography>
					) : sendOTPLoading ? (
						<Typography fontSize="14px" color="#ffffff">
							Resending OTP...
						</Typography>
					) : (
						<Link fontSize="14px" color="#ffffff" onClick={handleGetOTPButtonClick}>
							Resend OTP
						</Link>
					)}
				</Box>
			</Box>
		)
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" classes={{ paper: "secure-login-dialog-root" }}>
			<DialogContent classes={{ root: "secure-login-dialog" }}>
				<Box className="secure-login-dialog-header">
					<Typography variant="h5" fontWeight={500} textAlign="center" color="white">
						Secure Login
					</Typography>

					<Box className="secure-login-dialog-close-button">
						<IconButton onClick={handleClose} size="small" color="inherit">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				{ContentRenderer[renderContent]}
			</DialogContent>
		</Dialog>
	);
};

export default SecureLoginDialog;
