import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { NUMBER_PLATE_REGEX, VEHICLE_TYPES_LIST } from "../../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import { useAddVehicleMutation } from "../../../../redux/reducers/dataManagement.reducer";
import { useGetSocietyConfigQuery } from "../../../../redux/reducers/settings.reducer";
import { ISocietyConfigDetails } from "../../../../types";

interface IAddBlacklistVehicleDialogProps {
	open: boolean;
	onClose: () => void;
}

const AddBlacklistVehicleDialog: FC<IAddBlacklistVehicleDialogProps> = (props) => {
	const { open, onClose } = props;

	// APIS
	const { data: getSocietyConfigResponse } = useGetSocietyConfigQuery();
	const societyDetails: ISocietyConfigDetails | null =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	const [
		addBlacklistedVehicle,
		{
			isLoading: addBlacklistedVehicleLoading,
			isSuccess: addBlacklistedVehicleSuccess,
			error: addBlacklistedVehicleError,
			reset: resetAddBlacklistedVehicleState
		}
	] = useAddVehicleMutation();

	const {
		control,
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	function handleSubmitForm(data: FieldValues) {
		addBlacklistedVehicle({
			number_plate: String(data.vehicle_number).toUpperCase(),
			vehicle_type: Number(data.vehicle_type),
			auth_type: "blacklist",
			comment: data.reason,
			society: societyDetails ? societyDetails.id : 1
		});
	}

	useEffect(() => {
		if (open) {
			reset({
				vehicle_number: "",
				vehicle_type: "",
				reason: ""
			});
		} else {
			resetAddBlacklistedVehicleState();
		}
	}, [open, reset, resetAddBlacklistedVehicleState]);

	useEffect(() => {
		if (addBlacklistedVehicleSuccess) {
			resetAddBlacklistedVehicleState();
			onClose();
		}
	}, [addBlacklistedVehicleSuccess, onClose, resetAddBlacklistedVehicleState]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" color="white" classes={{ root: "dialog-title-root error" }}>
				Add Blacklist Vehicle
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Grid
					container
					rowSpacing={2}
					columnSpacing={4}
					component="form"
					noValidate
					onSubmit={handleSubmit(handleSubmitForm)}
				>
					<Grid item xs={12} md={6}>
						<InputLabel
							htmlFor="vehicle-number-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Vehicle Number
						</InputLabel>

						<TextField
							required
							fullWidth
							size="small"
							color="primary"
							variant="filled"
							id="vehicle-number-input"
							error={!!errors.vehicle_number}
							helperText={
								errors.vehicle_number && errors.vehicle_number.message ? errors.vehicle_number.message.toString() : ""
							}
							classes={{ root: "vehicle-number-input-root" }}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
							{...register("vehicle_number", {
								required: "Vehicle Number is required",
								pattern: { value: NUMBER_PLATE_REGEX, message: "Please enter a valid Vehicle Number" }
							})}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="vehicle_type"
							control={control}
							render={({ field: { value, onChange } }) => (
								<>
									<InputLabel
										htmlFor="blacklist-vehicle-type-input"
										sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
									>
										Vehicle Type
									</InputLabel>

									<FormControl
										required
										fullWidth
										hiddenLabel
										size="small"
										variant="filled"
										id="blacklist-vehicle-type-input"
										error={!!errors.vehicle_type}
									>
										<Select
											required
											fullWidth
											disableUnderline
											size="small"
											value={value}
											onChange={onChange}
											classes={{ root: "filled-input-root" }}
										>
											{VEHICLE_TYPES_LIST.map((vehicleTypeItem) => (
												<MenuItem value={vehicleTypeItem.id} key={vehicleTypeItem.id}>
													{vehicleTypeItem.name}
												</MenuItem>
											))}
										</Select>

										{errors.vehicle_type && errors.vehicle_type.message ? (
											<FormHelperText>{errors.vehicle_type.message.toString()}</FormHelperText>
										) : null}
									</FormControl>
								</>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<InputLabel htmlFor="reason-input" sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}>
							Reason
						</InputLabel>

						<TextField
							fullWidth
							multiline
							color="primary"
							variant="filled"
							id="reason-input"
							size="small"
							error={!!errors.reason}
							helperText={errors.reason && errors.reason.message ? errors.reason.message.toString() : ""}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "filled-input-root" } }}
							{...register("reason")}
						/>
					</Grid>

					{addBlacklistedVehicleError && "data" in addBlacklistedVehicleError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{addBlacklistedVehicleError.data ? String(addBlacklistedVehicleError.data) : "Something went wrong"}
							</Typography>
						</Grid>
					) : null}

					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<LoadingButton
								disableElevation
								variant="contained"
								color="success"
								type="submit"
								sx={{ paddingX: 5 }}
								loading={addBlacklistedVehicleLoading}
							>
								Save
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AddBlacklistVehicleDialog;
