import { InputBase, Popper, styled } from "@mui/material";

export const CustomAutocompletePopper = styled(Popper)(({ theme }) => ({
	border: "1px solid #e1e4e8",
	boxShadow: "0 8px 24px rgba(149, 157, 165, 0.2)",
	borderTopLeftRadius: 6,
	borderTopRightRadius: 6,
	width: 300,
	zIndex: theme.zIndex.modal,
	backgroundColor: "#fff"
}));

export const CustomAutocompleteInput = styled(InputBase)(({ theme }) => ({
	padding: 10,
	width: "100%",
	borderBottom: "1px solid #eaecef",
	"& input": {
		borderRadius: 4,
		backgroundColor: "#fff",
		padding: 8,
		border: "1px solid #eaecef",
		fontSize: 14,
		"&:focus": {
			border: `2px solid ${theme.palette.primary.main}`
		}
	}
}));
