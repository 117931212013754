import moment from "moment";
import {
	IFiltersReducerState,
	IGetEventLogsRequestData,
	ITimeFilter,
	LogFilterType,
	TIME_RANGE_OPTIONS_E,
	UpdateFilterStateCallbackType
} from "../../types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TIME_FILTER_FORMAT } from "../../utils/constants";

const initialState: IFiltersReducerState = {
	timeRange: TIME_RANGE_OPTIONS_E.TODAY,
	timeFilter: {
		start_time: moment().startOf("day").format(TIME_FILTER_FORMAT),
		end_time: moment().endOf("day").format(TIME_FILTER_FORMAT)
	},
	eventLogsType: null,
	eventLogsFilters: {
		page: 1,
		timestamp__gte: moment().startOf("day").format(TIME_FILTER_FORMAT),
		timestamp__lte: moment().endOf("day").format(TIME_FILTER_FORMAT)
	}
};

const filtersReducer = createSlice({
	name: "filters",
	initialState: initialState,
	reducers: {
		setEventLogsFilters: (
			state,
			{ payload }: PayloadAction<IGetEventLogsRequestData | UpdateFilterStateCallbackType<IGetEventLogsRequestData>>
		) => {
			if (typeof payload === "function") {
				state.eventLogsFilters = payload(state.eventLogsFilters);
			} else {
				state.eventLogsFilters = payload;
			}
		},

		setEventLogsType: (state, { payload }: PayloadAction<LogFilterType | null>) => {
			state.eventLogsType = payload;
		},

		setTimeFilter: (state, { payload }: PayloadAction<ITimeFilter>) => {
			state.timeFilter = payload;
		},

		setTimeRange: (state, { payload }: PayloadAction<TIME_RANGE_OPTIONS_E>) => {
			state.timeRange = payload;
		},

		resetAllFilters: (state) => {
			state.timeRange = initialState.timeRange;
			state.timeFilter = initialState.timeFilter;
			state.eventLogsType = initialState.eventLogsType;
			state.eventLogsFilters = initialState.eventLogsFilters;
		}
	}
});

export const { setEventLogsFilters, setEventLogsType, setTimeFilter, setTimeRange, resetAllFilters } =
	filtersReducer.actions;

export default filtersReducer;
