import {
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid";
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { ColumnVisibilityType } from ".";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useUpdateUserSettingsMutation } from "../../redux/reducers/eventLogs.reducer";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";

interface ITableSettingsDialogProps {
	open: boolean;
	columns: GridColDef[];
	columnVisibility: ColumnVisibilityType;
	onClose: (success?: boolean) => void;
	setColumnVisibility: Dispatch<SetStateAction<ColumnVisibilityType>>;
}

const TableSettingsDialog: FC<ITableSettingsDialogProps> = (props) => {
	const { open, columns, columnVisibility, onClose, setColumnVisibility } = props;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const SelectAllState = useMemo<"none" | "all" | "partial">(() => {
		const values = Object.values(columnVisibility).filter((item) => !!item.checked);

		if (values.length === 0) return "none";

		if (values.length === Object.keys(columnVisibility).length) return "all";

		return "partial";
	}, [columnVisibility]);

	// APIS
	const [
		updateUserSettings,
		{ isLoading: updateUserSettingsLoading, error: updateUserSettingsError, isSuccess: updateUserSettingsSuccess }
	] = useUpdateUserSettingsMutation();

	function handleChangeColumnVisibility(column: string, checked: boolean) {
		setColumnVisibility((currentColumnVisibility) => ({
			...currentColumnVisibility,
			[column]: {
				...currentColumnVisibility[column],
				checked: checked
			}
		}));
	}

	function handleChangeAllColumnsVisibility(checked: boolean) {
		setColumnVisibility((currentColumnVisibility) => {
			const updatedColumnVisibility = { ...currentColumnVisibility };

			for (const key of Object.keys(updatedColumnVisibility)) {
				if (!updatedColumnVisibility[key].disabled) {
					updatedColumnVisibility[key].checked = checked;
				}
			}

			return updatedColumnVisibility;
		});
	}

	function handleSaveButtonClick() {
		const requestData: { [columnName: string]: boolean } = {};

		for (const [columnName, visibilityDetails] of Object.entries(columnVisibility)) {
			requestData[columnName] = visibilityDetails.checked;
		}

		updateUserSettings({
			username: authState.user.username,
			data: {
				user_settings: {
					columns: requestData
				}
			}
		});
	}

	useEffect(() => {
		if (updateUserSettingsSuccess) {
			onClose(true);

			dispatch(
				showFeedbackNotification({
					message: "Table settings saved successfully",
					severity: "success"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateUserSettingsSuccess]);

	return (
		<Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Table Settings
				<Box className="close-dialog-icon-wrapper">
					<IconButton onClick={() => onClose()} color="inherit">
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Typography sx={{ marginBottom: "1rem" }}>Select Columns to view</Typography>

				<Grid container spacing={1}>
					<Grid item xs={12} md={4}>
						<FormControlLabel
							label="Select All"
							classes={{ root: "column-checkbox-item-root" }}
							control={
								<Checkbox
									checked={SelectAllState === "all"}
									indeterminate={SelectAllState === "partial"}
									onChange={(_event, checked) => handleChangeAllColumnsVisibility(checked)}
								/>
							}
						/>
					</Grid>

					{columns.map((columnItem) =>
						columnVisibility[columnItem.field] && columnVisibility[columnItem.field].visible ? (
							<Grid item xs={12} md={4} key={columnItem.field}>
								<Box className="column-checkbox-item">
									<FormControlLabel
										label={columnItem.headerName || columnItem.field}
										classes={{ root: "column-checkbox-item-root", label: "column-checkbox-item-label" }}
										disabled={columnVisibility[columnItem.field] && columnVisibility[columnItem.field].disabled}
										control={
											<Checkbox
												checked={columnVisibility[columnItem.field] && columnVisibility[columnItem.field].checked}
												onChange={(_event, checked) => handleChangeColumnVisibility(columnItem.field, checked)}
											/>
										}
									/>
								</Box>
							</Grid>
						) : null
					)}

					{updateUserSettingsError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{"data" in updateUserSettingsError && updateUserSettingsError.data
									? `${String(updateUserSettingsError.data).charAt(0).toUpperCase()}${String(
											updateUserSettingsError.data
									  ).slice(1)}`
									: ""}
							</Typography>
						</Grid>
					) : null}

					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<LoadingButton
								disableElevation
								variant="contained"
								color="success"
								startIcon={<SaveIcon />}
								loading={updateUserSettingsLoading}
								loadingPosition="start"
								onClick={handleSaveButtonClick}
							>
								Save Changes
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default TableSettingsDialog;
