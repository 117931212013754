import { Box, Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { FC, FormEvent, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import CloseIcon from "@mui/icons-material/Close";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { convertToTitleCase } from "../../../utils/commonUtils";
import { LoadingButton } from "@mui/lab";

interface ICustomAreasComponentProps {
	customAreasList: string[];
	initialCustomAreasList: string[];
	saveButtonLoading?: boolean;
	onUpdateCustomEntries: (newEntries: string[]) => void;
	onSave: () => void;
}

const CustomAreasComponent: FC<ICustomAreasComponentProps> = (props) => {
	const { customAreasList, initialCustomAreasList, saveButtonLoading, onUpdateCustomEntries, onSave } = props;

	const [customEntryInput, setCustomEntryInput] = useState<string>("");
	const [customEntryInputError, setCustomEntryInputError] = useState<string>("");
	// const [selectedEntriesIndex, setSelectedEntriesIndex] = useState<number[]>([]);
	// const [entrySelectionEnabled, setEntrySelectionEnabled] = useState<boolean>(false);

	function handleChangeCustomEntryInput(value: string) {
		setCustomEntryInput(value);
		if (value) setCustomEntryInputError("");
	}

	function handleAddCustomEntries(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!customEntryInput) {
			setCustomEntryInputError("Custom entry name is required");
		}

		const newCustomEntries: string[] = [...customAreasList];

		const entriesArray = customEntryInput.split(",");

		let errorEntries = "";

		for (const entryItem of entriesArray) {
			const trimmedEntry = entryItem.trim();
			if (!trimmedEntry) continue;

			const duplicateEntryIndex = newCustomEntries.findIndex(
				(item) => item.toLowerCase() === trimmedEntry.toLowerCase()
			);

			if (duplicateEntryIndex >= 0) {
				if (errorEntries) errorEntries = `${errorEntries}, `;
				errorEntries = `${errorEntries}${trimmedEntry}`;

				continue;
			}

			newCustomEntries.push(convertToTitleCase(trimmedEntry));
		}

		if (errorEntries) {
			setCustomEntryInputError("Custom entry already exists");
			setCustomEntryInput(errorEntries);
			return;
		}

		if (newCustomEntries.length <= 0) {
			setCustomEntryInputError("Enter valid entry names");
			return;
		}

		onUpdateCustomEntries(newCustomEntries);
		setCustomEntryInput("");
		setCustomEntryInputError("");
	}

	// function handleToggleEntrySelection(index: number) {
	// 	setSelectedEntriesIndex((currentSelectedEntriesIndex) => {
	// 		if (currentSelectedEntriesIndex.includes(index)) {
	// 			return currentSelectedEntriesIndex.filter((item) => item !== index);
	// 		}

	// 		const updatedSelectedEntriesIndex = [...currentSelectedEntriesIndex];
	// 		updatedSelectedEntriesIndex.push(index);
	// 		return updatedSelectedEntriesIndex;
	// 	});
	// }

	// function handleEnableEntrySelection() {
	// 	setEntrySelectionEnabled(true);
	// }

	// function handleDisableEntrySelection() {
	// 	setEntrySelectionEnabled(false);
	// 	setSelectedEntriesIndex([]);
	// }

	// function handleRemoveButtonClick() {
	// 	const updatedEntries = customAreasList.filter((_, index) => !selectedEntriesIndex.includes(index));
	// 	setSelectedEntriesIndex([]);
	// 	handleDisableEntrySelection();
	// 	onUpdateCustomEntries(updatedEntries);
	// }

	// useEffect(() => {
	// 	setSelectedEntriesIndex([]);
	// }, [customAreasList]);

	function isSaveButtonDisabled(): boolean {
		if (customEntryInput) return true;
		// if (entrySelectionEnabled) return true;

		if (JSON.stringify(customAreasList) === JSON.stringify(initialCustomAreasList)) return true;

		return false;
	}

	return (
		<Box className="custom-area-component-wrapper">
			<Box className="custom-area-inputs-wrapper">
				<InputLabel
					htmlFor="custom-area-input"
					sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
				>
					Custom Area
				</InputLabel>

				<Grid container spacing={2} alignItems="center" component="form" noValidate onSubmit={handleAddCustomEntries}>
					<Grid item xs={12} md={4}>
						<TextField
							fullWidth
							size="small"
							variant="filled"
							id="custom-area-input"
							placeholder="Enter custom area"
							value={customEntryInput}
							onChange={(event) => handleChangeCustomEntryInput(event.target.value)}
							error={!!customEntryInputError}
							helperText={customEntryInputError}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
						/>
					</Grid>

					<Grid item xs={12} md={8}>
						<Button disableElevation variant="contained" color="success" sx={{ paddingX: 3 }} type="submit">
							Add
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Typography variant="h6" color="var(--color-primary-dark)">
				Preview of Custom Area
			</Typography>

			{/* <Box className="building-config-actions-wrapper">
				{entrySelectionEnabled ? (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CloseIcon />}
						sx={{ minWidth: "100px" }}
						onClick={handleDisableEntrySelection}
					>
						Cancel
					</Button>
				) : (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CheckBoxOutlineBlankIcon />}
						sx={{ minWidth: "100px" }}
						onClick={handleEnableEntrySelection}
					>
						Select
					</Button>
				)}

				{entrySelectionEnabled ? (
					<Button
						variant="outlined"
						color="error"
						size="small"
						startIcon={<DeleteOutlineIcon />}
						sx={{ minWidth: "100px" }}
						disabled={selectedEntriesIndex.length <= 0}
						onClick={handleRemoveButtonClick}
					>
						Remove
					</Button>
				) : null}
			</Box> */}

			<Box className="buildings-flats-preview-wrapper">
				<Box className="buildings-flats-preview">
					{customAreasList.map((item, index) => (
						<Box className="building-flats-preview-item" key={index}>
							{/* {entrySelectionEnabled ? (
								<Box className="icon-wrapper" onClick={() => handleToggleEntrySelection(index)}>
									{selectedEntriesIndex.includes(index) ? (
										<CheckBoxIcon fontSize="inherit" />
									) : (
										<CheckBoxOutlineBlankIcon fontSize="inherit" />
									)}
								</Box>
							) : null} */}

							<Typography color="inherit" variant="body2" textAlign="center">
								{item}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>

			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<LoadingButton
					disableElevation
					variant="contained"
					color="success"
					startIcon={<SaveIcon />}
					sx={{ paddingX: 3 }}
					onClick={onSave}
					disabled={isSaveButtonDisabled()}
					loading={saveButtonLoading}
					loadingPosition="start"
				>
					Save
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default CustomAreasComponent;
