import { getAPIHeaderOptions } from "../../utils/apiUtils";
import {
	IGetDashboardChartResponseData,
	IGetDashboardMetricsRequestData,
	IGetDashboardMetricsResponseData,
	IGetDashboardNotificationsRequestData,
	IGetDashboardNotificationsResponseData,
	IGetTurnaroundLogsRequestData,
	IGetTurnaroundLogsResponse
} from "../../types";
import { queriesApi } from "./queries.reducer";

const dashboard = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET DASHBOARD METRICS
		getDashboardMetrics: builder.query<IGetDashboardMetricsResponseData, IGetDashboardMetricsRequestData>({
			query: (requestData) => ({
				url: "/analytics/dashboard_metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			}),
			keepUnusedDataFor: 10
		}),

		// GET DASHBOARD CHART
		getDashboardChart: builder.query<IGetDashboardChartResponseData[], IGetDashboardMetricsRequestData>({
			query: (requestData) => ({
				url: "/analytics/dashboard_graph",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			}),
			keepUnusedDataFor: 10
		}),

		// GET DASHBOARD NOTIFICATIONS
		getDashboardNotifications: builder.query<
			IGetDashboardNotificationsResponseData,
			IGetDashboardNotificationsRequestData
		>({
			query: (requestData) => ({
				url: "/notifications/notification",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...requestData, page_size: 10 }
			})
		}),

		// GET TURNAROUND LOGS
		getTurnaroundLogs: builder.query<IGetTurnaroundLogsResponse, IGetTurnaroundLogsRequestData>({
			query: (requestData) => ({
				url: `/analytics/turnaround_logs`,
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		})
	})
});

export const {
	// GET DASHBOARD METRICS
	useGetDashboardMetricsQuery,
	// GET DASHBOARD CHART
	useGetDashboardChartQuery,
	// GET DASHBOARD NOTIFICATIONS
	useGetDashboardNotificationsQuery,
	// GET TURNAROUND LOGS
	useGetTurnaroundLogsQuery
} = dashboard;
