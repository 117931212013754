import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import { DataGrid, GridColDef, GridPagination, GridPaginationModel } from "@mui/x-data-grid";
import React, { ChangeEvent, FC, FormEvent, useEffect, useMemo, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import "./reporting.css";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import NumberInput from "../../../components/NumberInput";
import AddVehicleAlertDialog from "./AddVehicleAlertDialog";
import {
	useEditSocietyConfigMutation,
	useGetSocietyConfigQuery,
	useGetVehiclesListQuery,
	useUpdateVehicleMutation
} from "../../../redux/reducers/settings.reducer";
import { isEqual } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import { IGetVehiclesListRequestData } from "../../../types";
import TablePagination from "../../../components/TablePagination";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { GridRenderCellParams } from "@mui/x-data-grid";

interface IVehicleAlertRowDetails {
	id: number;
	sr_no: number;
	vehicle_number: string;
}

interface IVehicleAlertFilters {
	search?: string;
}

interface IOverstayAlertInputs {
	enabled: boolean;
	duration: string;
}

const VehiclesPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const Reporting: FC = () => {
	const dispatch = useAppDispatch();

	const [vehicleAlertSearchInput, setVehicleAlertSearchInput] = useState<string>("");
	const [vehicleAlertFilters, setVehicleAlertFilters] = useState<IVehicleAlertFilters>({});
	const [showAddVehicleAlertDialog, setShowAddVehicleAlertDialog] = useState<boolean>(false);
	const [selectedVehicleDetails, setSelectedVehicleDetails] = useState<IVehicleAlertRowDetails | null>(null);
	const [overstayAlertInputs, setOverstayAlertInputs] = useState<IOverstayAlertInputs>({
		enabled: false,
		duration: ""
	});

	const [filters, setFilters] = useState<IGetVehiclesListRequestData>({
		page: 1,
		send_alert: true
	});

	// APIS
	// GET SOCIETY CONFIG
	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery(null);

	// EDIT SOCIETY CONFIG
	const [
		editSocietyConfig,
		{
			isLoading: editSocietyConfigLoading,
			error: editSocietyConfigError,
			isSuccess: editSocietyConfigSuccess,
			reset: resetEditSocietyConfig
		}
	] = useEditSocietyConfigMutation();

	// GET VEHICLES LIST
	const {
		data: getVehiclesListResponse,
		isLoading: getVehiclesListLoading,
		isFetching: getVehiclesListFetching,
		refetch: refetchVehiclesList
	} = useGetVehiclesListQuery(filters);

	// UPDATE VEHICLE
	const [
		updateVehicle,
		{
			isLoading: updateVehicleLoading,
			error: updateVehicleError,
			isSuccess: updateVehicleSuccess,
			reset: resetUpdateVehicleState
		}
	] = useUpdateVehicleMutation();

	const societyConfig = useMemo(
		() =>
			getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
				? getSocietyConfigResponse.results[0]
				: null,
		[getSocietyConfigResponse]
	);

	const initialOverstayAlertInputs = useMemo<IOverstayAlertInputs>(() => {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			const tatConfig = societyConfig.tat_config[0];
			return {
				enabled: tatConfig.email_alerts.enabled,
				duration: String(tatConfig.email_alerts.overstay_duration)
			};
		}

		return {
			enabled: false,
			duration: ""
		};
	}, [societyConfig]);

	const rowsData = useMemo<IVehicleAlertRowDetails[]>(() => {
		if (getVehiclesListResponse) {
			return getVehiclesListResponse.results.map((item, index) => ({
				id: item.id,
				sr_no: (filters.page - 1) * ROWS_PER_PAGE + index + 1,
				vehicle_number: item.number_plate
			}));
		}

		return [];
	}, [filters.page, getVehiclesListResponse]);

	function handleOpenAddVehicleAlertDialog() {
		setShowAddVehicleAlertDialog(true);
	}

	function handleCloseAddVehicleAlertDialog() {
		setShowAddVehicleAlertDialog(false);
		refetchVehiclesList();
	}

	function handleChangeOverstayAlertEnabled(checked: boolean) {
		setOverstayAlertInputs((currentInputs) => ({
			...currentInputs,
			enabled: checked
		}));
	}

	function handleChangeOverstayAlertDuration(value: string) {
		setOverstayAlertInputs((currentInputs) => ({
			...currentInputs,
			duration: value
		}));
	}

	function handleChangeVehicleAlertSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setVehicleAlertSearchInput(event.target.value);
	}

	function handleVehicleAlertSearchFormSubmit(event: FormEvent) {
		event.preventDefault();
		setVehicleAlertFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (vehicleAlertSearchInput) updatedFilters.search = vehicleAlertSearchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearVehicleAlertSearchInput() {
		setVehicleAlertSearchInput("");
		setVehicleAlertFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handleSaveButtonClick() {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			editSocietyConfig({
				id: societyConfig.id,
				data: {
					tat_config: [
						{
							...societyConfig.tat_config[0],
							email_alerts: {
								enabled: overstayAlertInputs.enabled,
								overstay_duration: Number(overstayAlertInputs.duration)
							}
						}
					]
				}
			});
		}
	}

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			page: event.page + 1
		}));
	}

	function handleOpenDeleteVehicleConfirmationDialog(vehicleDetails: IVehicleAlertRowDetails) {
		setSelectedVehicleDetails(vehicleDetails);
	}

	function handleCloseDeleteVehicleConfirmationDialog() {
		setSelectedVehicleDetails(null);
	}

	function handleConfirmDeleteVehicle() {
		if (selectedVehicleDetails) {
			updateVehicle({
				id: selectedVehicleDetails.id,
				data: {
					number_plate: selectedVehicleDetails.vehicle_number,
					send_alert: false
				}
			});
		}
	}

	useEffect(() => {
		if (societyConfig) {
			if (societyConfig.tat_config && societyConfig.tat_config.length > 0) {
				const tatConfig = societyConfig.tat_config[0];
				setOverstayAlertInputs({
					enabled: tatConfig.email_alerts.enabled,
					duration: String(tatConfig.email_alerts.overstay_duration)
				});
			}
		}
	}, [societyConfig]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Something went wrong",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	useEffect(() => {
		if (editSocietyConfigSuccess) {
			dispatch(
				showFeedbackNotification({
					message: "Changes saved successfully",
					severity: "success"
				})
			);

			refetchSocietyConfig();
			resetEditSocietyConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigSuccess]);

	useEffect(() => {
		if (updateVehicleError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in updateVehicleError && updateVehicleError.data
							? String(updateVehicleError.data)
							: "Failed to remove vehicle number alert",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicleError]);

	useEffect(() => {
		if (updateVehicleSuccess) {
			handleCloseDeleteVehicleConfirmationDialog();
			resetUpdateVehicleState();
			refetchVehiclesList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicleSuccess]);

	const vehicleAlertColumnsData: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr. No.", width: 100, sortable: false },
		{ field: "vehicle_number", headerName: "Vehicle Number", minWidth: 200, flex: 1, sortable: false },
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IVehicleAlertRowDetails>) => (
				<Box className="communication-channels-table-actions-column-cell">
					<IconButton size="small" color="error" onClick={() => handleOpenDeleteVehicleConfirmationDialog(params.row)}>
						<DeleteIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="communication-channels-screen-wrapper">
			<Box className="overstay-alerts-title-wrapper">
				<Typography variant="h6" color="var(--color-primary-main)">
					Overstay Alerts
				</Typography>

				<Switch
					checked={overstayAlertInputs.enabled}
					onChange={(_event, checked) => handleChangeOverstayAlertEnabled(checked)}
				/>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<InputLabel
						htmlFor="duration-input"
						disabled={!overstayAlertInputs.enabled}
						sx={{ color: "#3d3d3d", marginBottom: "0.5rem" }}
					>
						Duration
					</InputLabel>

					<NumberInput
						fullWidth
						positiveOnly
						disableSigned
						disableDecimal
						size="small"
						variant="outlined"
						id="duration-input"
						placeholder="Enter duration"
						value={overstayAlertInputs.duration}
						disabled={!overstayAlertInputs.enabled}
						onChange={(event) => handleChangeOverstayAlertDuration(event.target.value)}
						InputProps={{
							endAdornment: <InputAdornment position="end">m</InputAdornment>
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						startIcon={<SaveIcon />}
						disabled={isEqual(initialOverstayAlertInputs, overstayAlertInputs)}
						onClick={handleSaveButtonClick}
						loading={editSocietyConfigLoading}
					>
						Save Changes
					</LoadingButton>
				</Grid>
			</Grid>

			<Typography variant="h6" color="var(--color-primary-main)">
				Vehicle Number Alerts
			</Typography>

			<Box className="communication-channels-screen-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 2 }}
					onClick={handleOpenAddVehicleAlertDialog}
				>
					Add
				</Button>

				<Box component="form" noValidate onSubmit={handleVehicleAlertSearchFormSubmit}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={vehicleAlertSearchInput}
						onChange={handleChangeVehicleAlertSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" },
							endAdornment: (
								<InputAdornment position="end">
									{vehicleAlertFilters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearVehicleAlertSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</Box>

			<Box className="communication-channels-table-wrapper">
				<DataGrid
					columns={vehicleAlertColumnsData}
					rows={rowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[ROWS_PER_PAGE]}
					onPaginationModelChange={handlePageChange}
					rowCount={getVehiclesListResponse?.count ?? 0}
					paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					slots={{ pagination: VehiclesPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					hideFooter={(getVehiclesListResponse?.count ?? 0) <= ROWS_PER_PAGE}
					loading={getVehiclesListLoading || getVehiclesListFetching}
					classes={{
						columnSeparator: "communication-channels-table-column-separator",
						columnHeader: "communication-channels-table-column-header"
					}}
				/>
			</Box>

			<AddVehicleAlertDialog open={showAddVehicleAlertDialog} onClose={handleCloseAddVehicleAlertDialog} />

			<ConfirmationDialog
				open={!!selectedVehicleDetails}
				title="Remove Vehicle Number Alert"
				heading="Are you sure you want to remove alert for the vehicle number?"
				onClose={handleCloseDeleteVehicleConfirmationDialog}
				onConfirm={handleConfirmDeleteVehicle}
				confirmButtonLoading={updateVehicleLoading}
				width="sm"
				color="error"
			/>
		</Box>
	);
};

export default Reporting;
