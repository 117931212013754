import { getAPIHeaderOptions } from "../../utils/apiUtils";
import { IGetUptimeMetricsRequestData, IGetUptimeMetricsResponseData } from "../../types";
import { queriesApi } from "./queries.reducer";

const uptime = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET UPTIME METRICS
		getUptimeMetrics: builder.query<IGetUptimeMetricsResponseData, IGetUptimeMetricsRequestData>({
			query: (requestData) => ({
				url: "/devices/uptime_metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		})
	})
});

export const {
	// GET UPTIME METRICS
	useGetUptimeMetricsQuery
} = uptime;
