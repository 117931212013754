import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import "./dashboard.css";
import { DASHBOARD_NOTIFICATIONS_AUTO_REFRESH_INTERVAL, TIME_FILTER_FORMAT } from "../../utils/constants";
import { IGetDashboardMetricsRequestData } from "../../types";
import DeviceSelection from "../../components/DeviceSelection";
import { getFormattedDuration } from "../../utils/commonUtils";
import TimeRangeSelection from "../../components/TimeRangeSelection";
import DeviceStatusTooltip from "../../components/DeviceStatusTooltip";
import { useGetDevicesListQuery } from "../../redux/reducers/settings.reducer";
import {
	useGetDashboardChartQuery,
	useGetDashboardMetricsQuery,
	useGetDashboardNotificationsQuery
} from "../../redux/reducers/dashboard.reducer";
import { ReactComponent as UptimeIcon } from "../../assets/icons/uptime.svg";
import { ReactComponent as CarExitIcon } from "../../assets/icons/car-exit.svg";
import { ReactComponent as DowntimeIcon } from "../../assets/icons/downtime.svg";
import { ReactComponent as CarEntryIcon } from "../../assets/icons/car-entry.svg";
import { ReactComponent as BikeExitIcon } from "../../assets/icons/bike-exit.svg";
import { ReactComponent as BikeEntryIcon } from "../../assets/icons/bike-entry.svg";
import { useAppSelector } from "../../redux";

interface ILineChartData {
	timestamps: string[];
	entry: number[];
	exit: number[];
}

const Dashboard: FC = () => {
	// STORE
	const filtersState = useAppSelector((state) => state.filters);
	const { timeFilter } = filtersState;

	// STATES
	const [filters, setFilters] = useState<IGetDashboardMetricsRequestData>({
		start_time: moment().startOf("day").format(TIME_FILTER_FORMAT),
		end_time: moment().endOf("day").format(TIME_FILTER_FORMAT)
	});
	const [lineChartData, setLineChartData] = useState<ILineChartData>({
		timestamps: [],
		entry: [],
		exit: []
	});

	const lineChartDataFilter = useMemo<IGetDashboardMetricsRequestData>(() => {
		const filterData: IGetDashboardMetricsRequestData = {
			start_time: filters.start_time,
			end_time: filters.end_time,
			device_id: filters.device_id
		};

		if (moment(filters.end_time, TIME_FILTER_FORMAT).isSame(new Date(), "day")) {
			filterData.end_time = moment().format(TIME_FILTER_FORMAT);
		}

		return filterData;
	}, [filters.device_id, filters.end_time, filters.start_time]);

	// APIS
	const {
		data: getDevicesListResponse,
		isLoading: getDevicesListLoading,
		isFetching: getDevicesListFetching
	} = useGetDevicesListQuery({});
	const {
		data: dashboardMetricsResponse,
		isLoading: getDashboardMetricsLoading,
		isFetching: getDashboardMetricsFetching
	} = useGetDashboardMetricsQuery(filters);
	const {
		data: dashboardChartData,
		isLoading: getDashboardChartLoading,
		isFetching: getDashboardChartFetching
	} = useGetDashboardChartQuery(lineChartDataFilter);

	const {
		data: getDashboardNotificationsResponse,
		isLoading: getDashboardNotificationsLoading,
		isFetching: getDashboardNotificationsFetching
	} = useGetDashboardNotificationsQuery(
		{ mark_as_read: false },
		{ pollingInterval: DASHBOARD_NOTIFICATIONS_AUTO_REFRESH_INTERVAL * 1000 }
	);

	const devicesList = getDevicesListResponse?.results ?? [];

	// UPDATE FILTERS FUNCTIONS
	function handleDeviceChange(deviceId: number) {
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (deviceId >= 0) updatedFilters.device_id = deviceId;
			else delete updatedFilters.device_id;

			return updatedFilters;
		});
	}

	function handleChangeSelectedDate(from: string, to: string) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			start_time: from,
			end_time: to
		}));
	}

	// RENDER UTIL FUNCTIONS
	function getDeviceHours(type: "uptime" | "downtime"): string {
		if (!dashboardMetricsResponse) return "--";

		if (type === "uptime") return getFormattedDuration(dashboardMetricsResponse.uptime_data.uptime_seconds);
		else if (type === "downtime") return getFormattedDuration(dashboardMetricsResponse.uptime_data.downtime_seconds);

		return "--";
	}

	useEffect(() => {
		if (dashboardChartData) {
			const timestamps: string[] = [];
			const entry: number[] = [];
			const exit: number[] = [];

			for (const chartDataItem of dashboardChartData) {
				timestamps.push(chartDataItem.timestamp);
				entry.push(chartDataItem.entry);
				exit.push(chartDataItem.exit);
			}

			setLineChartData({ timestamps, entry, exit });
		}
	}, [dashboardChartData]);

	useEffect(() => {
		if (timeFilter && timeFilter.start_time && timeFilter.end_time) {
			handleChangeSelectedDate(timeFilter.start_time, timeFilter.end_time);
		}
	}, [timeFilter]);

	return (
		<Box className="dashboard-screen-wrapper">
			<Box className="dashboard-filters-section">
				<DeviceSelection colorMode="dark" onChange={handleDeviceChange} />
				<TimeRangeSelection customOnly />
			</Box>

			<Grid container columnSpacing={2}>
				<Grid item xs={12} md={9}>
					{getDashboardChartLoading || getDashboardChartFetching ? (
						<Skeleton variant="rounded" animation="wave" height="calc(375px + 2rem)" />
					) : (
						<Box className="dashboard-chart-wrapper">
							<ReactECharts
								style={{ minHeight: "375px" }}
								showLoading={getDashboardChartLoading || getDashboardChartFetching}
								option={{
									color: ["#388DFF", "#E53935"],
									tooltip: { trigger: "axis" },
									grid: { left: 0, right: 0, bottom: 0, containLabel: true },
									legend: {
										left: "10%",
										data: [
											{ name: "Entry", lineStyle: { color: "#388DFF", width: 3 }, itemStyle: { opacity: 0 } },
											{ name: "Exit", lineStyle: { color: "#E53935", width: 3 }, itemStyle: { opacity: 0 } }
										]
									},
									xAxis: {
										type: "category",
										boundaryGap: true,
										axisLine: { show: lineChartData.entry.length > 0 || lineChartData.exit.length > 0 },
										axisTick: { alignWithLabel: true },
										data: lineChartData.timestamps
									},
									yAxis: {
										type: "value",
										axisLine: { show: lineChartData.entry.length > 0 || lineChartData.exit.length > 0 },
										splitLine: { show: false },
										axisLabel: { margin: 12 }
									},
									series: [
										{
											name: "Entry",
											data: lineChartData.entry,
											type: "line",
											smooth: 0.5,
											symbol: "none",
											lineStyle: { color: "#388DFF", width: 3 },
											areaStyle: {
												color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
													{ offset: 0, color: "rgba(56,142,255,0.18)" },
													{ offset: 1, color: "rgba(56,142,255,0)" }
												])
											}
										},
										{
											name: "Exit",
											data: lineChartData.exit,
											type: "line",
											smooth: 0.5,
											symbol: "none",
											lineStyle: { color: "#E53935", width: 3 },
											areaStyle: {
												color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
													{ offset: 0, color: "rgba(229,56,53,0.18)" },
													{ offset: 1, color: "rgba(229,56,53,0)" }
												])
											}
										}
									]
								}}
							/>

							{!(getDashboardChartLoading || getDashboardChartFetching) &&
							lineChartData.entry.length <= 0 &&
							lineChartData.exit.length <= 0 ? (
								<Box className="line-chart-no-data-overlay">
									<Typography>No Data</Typography>
								</Box>
							) : null}
						</Box>
					)}
				</Grid>

				<Grid item xs={12} md={3}>
					{getDashboardMetricsLoading || getDashboardMetricsFetching ? (
						<Skeleton variant="rounded" animation="wave" height="100%" />
					) : (
						<Box className="dashboard-metrics-section-wrapper">
							<Box className="dashboard-metrics-card">
								<Box className="dashboard-metrics-card-icon info">
									<CarEntryIcon width={24} />
								</Box>

								<Box className="dashboard-metrics-card-text">
									<Typography variant="body2" color="#00000080">
										Total Entries
									</Typography>

									<Typography fontWeight={500}>
										{Intl.NumberFormat("en-IN").format(
											dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.total.entry : 0
										)}
									</Typography>
								</Box>
							</Box>

							<Divider />

							<Box className="dashboard-metrics-card">
								<Box className="dashboard-metrics-card-icon error">
									<CarExitIcon width={24} />
								</Box>

								<Box className="dashboard-metrics-card-text">
									<Typography variant="body2" color="#00000080">
										Total Exits
									</Typography>

									<Typography fontWeight={500}>
										{Intl.NumberFormat("en-IN").format(
											dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.total.exit : 0
										)}
									</Typography>
								</Box>
							</Box>

							<Divider />

							<Box className="dashboard-metrics-card">
								<Box className="dashboard-metrics-card-icon success">
									<UptimeIcon width={24} />
								</Box>

								<Box className="dashboard-metrics-card-text">
									<Box className="info-text">
										<Typography variant="body2" color="#00000080">
											Uptime
										</Typography>

										<DeviceStatusTooltip
											arrow
											placement="top"
											title="Today's uptime calculation might be inaccurate due to internet connectivity fluctuations"
											slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -8] } }] } }}
										>
											<InfoOutlinedIcon fontSize="inherit" sx={{ color: "var(--color-info-dark)" }} />
										</DeviceStatusTooltip>
									</Box>

									<Typography fontWeight={500}>
										{getDeviceHours("uptime")}
										{/* <Typography component="span" fontWeight={500} color="var(--color-success-main)">
													{` (88%)`}
												</Typography> */}
									</Typography>
								</Box>
							</Box>

							<Divider />

							<Box className="dashboard-metrics-card">
								<Box className="dashboard-metrics-card-icon warning">
									<DowntimeIcon width={24} />
								</Box>

								<Box className="dashboard-metrics-card-text">
									<Box className="info-text">
										<Typography variant="body2" color="#00000080">
											Downtime
										</Typography>

										<DeviceStatusTooltip
											arrow
											placement="top"
											title="Today's downtime calculation might be inaccurate due to internet connectivity fluctuations"
											slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -8] } }] } }}
										>
											<InfoOutlinedIcon fontSize="inherit" sx={{ color: "var(--color-info-dark)" }} />
										</DeviceStatusTooltip>
									</Box>

									<Typography fontWeight={500}>
										{getDeviceHours("downtime")}
										{/* <Typography
													component="span"
													fontWeight={500}
													color="var(--color-error-main)"
												>{` (10%)`}</Typography> */}
									</Typography>
								</Box>
							</Box>
						</Box>
					)}
				</Grid>
			</Grid>

			<Box className="dashboard-data-section-wrapper">
				<Box className="dashboard-stats-section-wrapper">
					{getDashboardMetricsLoading || getDashboardMetricsFetching ? (
						<>
							<Skeleton variant="rounded" animation="wave" height={148} sx={{ flex: 1 }} />
							<Skeleton variant="rounded" animation="wave" height={148} sx={{ flex: 1 }} />
						</>
					) : (
						<>
							<Box className="dashboard-stats-section">
								<Typography variant="h6" color="#2B64AF">
									Entry
								</Typography>

								<Divider sx={{ borderColor: "#2B64AF", marginTop: "0.5rem", marginBottom: "0.75rem" }} />

								<Box className="vehicle-stats-wrapper">
									<Box className="vehicle-stats auth">
										<Typography fontWeight={500}>Authorized</Typography>

										<Box className="vehicle-type-counts-wrapper">
											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<CarEntryIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.entry.four_wheeler.auth : 0
													)}
												</Typography>
											</Box>

											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<BikeEntryIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.entry.two_wheeler.auth : 0
													)}
												</Typography>
											</Box>
										</Box>
									</Box>

									<Box className="vehicle-stats unauth">
										<Typography fontWeight={500}>Unauthorized</Typography>

										<Box className="vehicle-type-counts-wrapper">
											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<CarEntryIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse
															? dashboardMetricsResponse.vehicle_data.entry.four_wheeler.unauth
															: 0
													)}
												</Typography>
											</Box>

											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<BikeEntryIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse
															? dashboardMetricsResponse.vehicle_data.entry.two_wheeler.unauth
															: 0
													)}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box className="dashboard-stats-section">
								<Typography variant="h6" color="#EF5350">
									Exit
								</Typography>

								<Divider sx={{ borderColor: "#EF5350", marginTop: "0.5rem", marginBottom: "0.75rem" }} />

								<Box className="vehicle-stats-wrapper">
									<Box className="vehicle-stats auth">
										<Typography fontWeight={500}>Authorized</Typography>

										<Box className="vehicle-type-counts-wrapper">
											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<CarExitIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.exit.four_wheeler.auth : 0
													)}
												</Typography>
											</Box>

											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<BikeExitIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.exit.two_wheeler.auth : 0
													)}
												</Typography>
											</Box>
										</Box>
									</Box>

									<Box className="vehicle-stats unauth">
										<Typography fontWeight={500}>Unauthorized</Typography>

										<Box className="vehicle-type-counts-wrapper">
											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<CarExitIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse
															? dashboardMetricsResponse.vehicle_data.exit.four_wheeler.unauth
															: 0
													)}
												</Typography>
											</Box>

											<Box className="vehicle-type-count">
												<Box className="vehicle-type-count-icon">
													<BikeExitIcon fill="inherit" width={24} />
												</Box>

												<Typography fontWeight={500} color="#000000C2">
													{Intl.NumberFormat("en-IN").format(
														dashboardMetricsResponse ? dashboardMetricsResponse.vehicle_data.exit.two_wheeler.unauth : 0
													)}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Box>

				<Box className="dashboard-info-section-wrapper">
					{getDevicesListLoading || getDevicesListFetching ? (
						<Skeleton variant="rounded" animation="wave" height="100%" sx={{ flex: 0.35, minHeight: "150px" }} />
					) : (
						<Box className="dashboard-device-status-section-wrapper">
							<Typography variant="h6" fontWeight={500}>
								Device Status
							</Typography>

							<Box className="dashboard-device-details-wrapper">
								{devicesList.map((deviceItem) => (
									<Box className="dashboard-device-details" key={deviceItem.id}>
										<Box className="dashboard-device-details-icon">
											<DeveloperBoardIcon />
										</Box>

										<Box className="dashboard-device-details-text">
											<Typography fontWeight={500}>{deviceItem.device_name}</Typography>

											<Box className="dashboard-device-status">
												<FiberManualRecordIcon
													classes={{ root: "dashboard-device-status-icon" }}
													color={deviceItem.device_status === "Online" ? "success" : "error"}
												/>

												<Typography variant="subtitle2" fontSize={13} lineHeight={1.3}>
													{deviceItem.device_status === "Online" ? "Online" : "Offline"}
												</Typography>
											</Box>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					)}

					{!getDashboardNotificationsResponse &&
					(getDashboardNotificationsLoading || getDashboardNotificationsFetching) ? (
						<Skeleton variant="rounded" animation="wave" height="100%" sx={{ flex: 0.65, minHeight: "150px" }} />
					) : (
						<Box className="dashboard-notifications-section-wrapper">
							<Typography variant="h6" fontWeight={500}>
								Notifications
							</Typography>

							<Box className="dashboard-notifications-list">
								{getDashboardNotificationsResponse
									? getDashboardNotificationsResponse.results.map((notificationItem) => (
											<Box className={`dashboard-notification error`}>
												<Box className="dashboard-notification-icon-wrapper">
													<CarEntryIcon width={28} fill="white" />
												</Box>

												<Box className="dashboard-notification-info-wrapper">
													<Typography fontWeight={500}>{notificationItem.header}</Typography>

													<Typography variant="body2" color="#000000C2">
														{notificationItem.body}
													</Typography>
												</Box>
											</Box>
									  ))
									: null}
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Dashboard;
