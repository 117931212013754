import React from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const SecureLoginTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip
		arrow
		placement="top"
		slotProps={{ popper: { modifiers: [{ name: "offset", options: { offset: [0, -8] } }] } }}
		classes={{ popper: className }}
		{...props}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#D7D1FF",
		color: theme.palette.text.primary,
		fontSize: 14,
		boxShadow: theme.shadows[2],
		fontWeight: 400,
		textAlign: "center",
		maxWidth: 200
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: "#D7D1FF"
	}
}));
