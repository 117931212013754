import { FilledInputProps, InputProps, OutlinedInputProps, TextField, TextFieldProps } from "@mui/material";
import React, { FormEvent, forwardRef, KeyboardEvent, WheelEvent } from "react";

interface INumberInputRestProps {
	isContactNumber?: boolean;
	positiveOnly?: boolean;
	disableSigned?: boolean;
	disableDecimal?: boolean;
	password?: boolean;
	maxLength?: number;
}

type NumberInputPropsType = INumberInputRestProps & TextFieldProps;

type TextFieldInputPropsType =
	| Partial<FilledInputProps>
	| Partial<OutlinedInputProps>
	| Partial<InputProps>
	| undefined;

const NumberInput = forwardRef<HTMLInputElement, NumberInputPropsType>((props, ref) => {
	const {
		isContactNumber,
		positiveOnly,
		disableSigned,
		disableDecimal,
		InputProps,
		password,
		maxLength,
		...restProps
	} = props;

	function handleWheelEvent(event: WheelEvent<HTMLDivElement>) {
		event.currentTarget.querySelector("input")?.blur();
	}

	function handleKeyDownEvent(event: KeyboardEvent<HTMLDivElement>) {
		if (event.key === "e" || event.key === "E") {
			event.preventDefault();
		}

		if (disableSigned && (event.key === "-" || event.key === "+")) {
			event.preventDefault();
		}

		if (disableDecimal && event.key === ".") {
			event.preventDefault();
		}
	}

	function handleInputEvent(event: FormEvent<HTMLDivElement>) {
		if (password) {
			const InputElement = event.target as HTMLInputElement;
			InputElement.value = InputElement.value.replace(/\D/g, "");
		}

		if (typeof maxLength === "number") {
			const InputElement = event.target as HTMLInputElement;
			InputElement.value = InputElement.value.slice(0, maxLength);
		}

		if (isContactNumber) {
			const InputElement = event.target as HTMLInputElement;
			InputElement.value = Math.max(0, parseInt(InputElement.value)).toString().slice(0, 10);
		}
	}

	function getInputProps(): TextFieldInputPropsType {
		const newInputProps: TextFieldInputPropsType = { ...InputProps };

		if (positiveOnly) {
			newInputProps.inputProps = {
				...newInputProps.inputProps,
				min: 0
			};
		}

		return newInputProps;
	}

	return (
		<TextField
			type={password ? "password" : "number"}
			InputProps={getInputProps()}
			onWheel={handleWheelEvent}
			onKeyDown={handleKeyDownEvent}
			onInput={handleInputEvent}
			ref={ref}
			{...restProps}
		/>
	);
});

export default NumberInput;
