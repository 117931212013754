import { IGetEventLogsRequestData, LogFilterType } from "./eventLogs.types";

export type UpdateFilterStateCallbackType<T = unknown> = (currentStateData: T) => T;

export enum TIME_RANGE_OPTIONS_E {
	TODAY = "today",
	YESTERDAY = "yesterday",
	WEEK = "week",
	MONTH = "month",
	QUARTER = "quarter",
	HALF_YEAR = "half_year",
	CUSTOM = "custom"
}

export interface ITimeFilter {
	start_time: string;
	end_time: string;
}

export interface IFiltersReducerState {
	eventLogsFilters: IGetEventLogsRequestData;
	eventLogsType: LogFilterType | null;
	timeFilter: ITimeFilter;
	timeRange: TIME_RANGE_OPTIONS_E;
}
