import { Box, Container, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import "./login.css";
import { ReactComponent as GGConnectLogo } from "../../assets/logos/gg-connect-logo-new.svg";
import { ReactComponent as GGConnectLoginImage } from "../../assets/images/gg-connect-login.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { loginUser } from "../../redux/reducers/auth.reducer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { queriesApi } from "../../redux/reducers/queries.reducer";
import { resetAllFilters } from "../../redux/reducers/filters.reducer";

const Login: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const authState = useAppSelector((state) => state.auth);

	const [searchParams] = useSearchParams();

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	function handleSubmitForm(data: FieldValues) {
		dispatch(loginUser({ username: data.username, password: data.password }));
	}

	useEffect(() => {
		reset({
			username: "",
			password: ""
		});
	}, [reset]);

	useEffect(() => {
		if (authState.user.token && authState.user.token !== "invalid") {
			if (searchParams.has("path")) {
				let path = searchParams.get("path") ?? "";

				path = `${path}?`;

				searchParams.forEach((value, key) => {
					if (key !== "path") {
						path = `${path}${key}=${value}&`;
					}
				});

				navigate(path);
			} else {
				navigate("/dashboard");
			}
		} else {
			dispatch(queriesApi.util.resetApiState());
			dispatch(resetAllFilters());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.user.token, navigate]);

	return (
		<Box className="login-component-wrapper">
			<Container maxWidth="xl" sx={{ height: "100%" }}>
				<Box className="login-content-wrapper">
					<Box className="login-image-section">
						<GGConnectLogo width="85%" />

						{/* <Box>
							<Typography variant="h3" color="var(--color-primary-main)" fontSize={42} fontWeight={500}>
								GateGuard Connect
							</Typography>
						</Box> */}

						<GGConnectLoginImage width="90%" />
					</Box>

					<Box className="login-form-section">
						<Typography variant="h4" color="#F4F4FE" fontWeight={500}>
							Enter Login Details
						</Typography>

						<Box className="login-form-wrapper" component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
							<Box className="login-input-wrapper">
								<Typography variant="h6" fontWeight={400} color="inherit">
									Username
								</Typography>

								<TextField
									required
									fullWidth
									variant="filled"
									color="primary"
									placeholder="Enter username"
									InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "login-input-root" } }}
									error={!!errors.username}
									helperText={errors.username && errors.username.message ? errors.username.message.toString() : ""}
									{...register("username", { required: "Username is required" })}
								/>
							</Box>

							<Box className="login-input-wrapper">
								<Typography variant="h6" fontWeight={400} color="inherit">
									Password
								</Typography>

								<TextField
									required
									fullWidth
									variant="filled"
									color="primary"
									placeholder="Enter password"
									type="password"
									InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "login-input-root" } }}
									error={!!errors.password}
									helperText={errors.password && errors.password.message ? errors.password.message.toString() : ""}
									{...register("password", { required: "Password is required" })}
								/>
							</Box>

							{authState.currentAction === "auth/loginUser" && authState.error ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Box
										sx={{
											backgroundColor: "var(--color-error-light)",
											padding: "0.25rem 0.5rem",
											border: "1px solid var(--color-error-main)",
											borderRadius: "4px"
										}}
									>
										<Typography variant="body2" textAlign="center" color="var(--color-error-main)">
											{authState.error}
										</Typography>
									</Box>
								</Box>
							) : null}

							<LoadingButton
								disableElevation
								variant="contained"
								color="success"
								sx={{ paddingY: 1.5, textTransform: "capitalize" }}
								type="submit"
								loading={authState.currentAction === "auth/loginUser" && authState.loading}
							>
								Login
							</LoadingButton>
						</Box>

						<Box className="login-form-background" />
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Login;
