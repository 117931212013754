import { getAPIHeaderOptions } from "../../utils/apiUtils";
import {
	ExcelValidationKeyTypes,
	IEditSocietyRequestData,
	IExcelValidationDetails,
	IGetCameraFramesListRequestData,
	IGetCameraFramesResponse,
	IGetCameraNamesResponseData,
	IGetDevicesListRequestData,
	IGetDevicesListResponseData,
	IGetOwnerTypesListResponseData,
	IGetSocietyConfigResponseData,
	IGetVehiclesListRequestData,
	IGetVehiclesListResponse,
	IGetVisitorTypesResponseData,
	ISystemIntegratorDetails,
	IUpdateVehicleRequestData
} from "../../types";
import { queriesApi } from "./queries.reducer";

const settings = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET OWNER TYPES
		getOwnerTypes: builder.query<IGetOwnerTypesListResponseData, void>({
			query: () => ({
				url: "/analytics/ownertype",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET VISITOR TYPES
		getVisitorTypes: builder.query<IGetVisitorTypesResponseData, void>({
			query: () => ({
				url: "/analytics/visitortype",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET DEVICES LIST
		getDevicesList: builder.query<IGetDevicesListResponseData, IGetDevicesListRequestData>({
			query: () => ({
				url: "/devices/device",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET CAMERA NAMES
		getCameraNames: builder.query<IGetCameraNamesResponseData[], void>({
			query: () => ({
				url: "/analytics/camera_names",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET SOCIETY CONFIG
		getSocietyConfig: builder.query<IGetSocietyConfigResponseData, void | null>({
			query: () => ({
				url: "/analytics/society",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// EDIT SOCIETY CONFIG
		editSocietyConfig: builder.mutation<unknown, IEditSocietyRequestData>({
			query: (requestData) => ({
				url: `/analytics/society/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			})
		}),

		// GET FLATS LIST
		getFlatsList: builder.query<string[], void>({
			query: () => ({
				url: `/analytics/get_flat_numbers`,
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET CAMERA FRAMES LIST
		getCameraFramesList: builder.query<IGetCameraFramesResponse[], IGetCameraFramesListRequestData>({
			query: (requestData) => ({
				url: "/devices/camera_frames",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// GET SYSTEM INTEGRATOR DETAILS
		getSystemIntegratorDetails: builder.query<ISystemIntegratorDetails[], void>({
			query: () => ({
				url: "/system-integrators/system-integrator-data",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			})
		}),

		// GET EXCEL VALIDATIONS
		getExcelValidations: builder.query<IExcelValidationDetails, void>({
			query: () => ({
				url: "/analytics/excel-validations",
				headers: getAPIHeaderOptions(true),
				method: "GET"
			}),
			transformResponse: (response: { data: IExcelValidationDetails }) => {
				for (const key of Object.keys(response.data)) {
					const objectKey = key as ExcelValidationKeyTypes;

					if (response.data[objectKey].regex) {
						const regexString = String(response.data[objectKey].regex);
						const regexParts = regexString.match(/^\/(.*?)\/([gimsuy]*)$/);

						if (regexParts) {
							const regexPattern = regexParts[1];
							const regexFlags = regexParts[2];
							const regex = new RegExp(regexPattern, regexFlags);

							response.data[objectKey].regex = regex;
						} else {
							response.data[objectKey].regex = new RegExp(regexString);
						}
					}
				}

				return response.data;
			}
		}),

		// GET VEHICLES LIST
		getVehiclesList: builder.query<IGetVehiclesListResponse, IGetVehiclesListRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// UPDATE VEHICLE
		updateVehicle: builder.mutation<unknown, IUpdateVehicleRequestData>({
			query: (requestData) => ({
				url: `/analytics/vehicle/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			})
		})
	})
});

export const {
	// GET OWNER TYPES
	useGetOwnerTypesQuery,
	// GET VISITOR TYPES
	useGetVisitorTypesQuery,
	// GET DEVICES LIST
	useGetDevicesListQuery,
	// GET CAMERA NAMES
	useGetCameraNamesQuery,
	// GET SOCIETY CONFIG
	useGetSocietyConfigQuery,
	// EDIT SOCIETY CONFIG
	useEditSocietyConfigMutation,
	// GET FLATS LIST
	useGetFlatsListQuery,
	// GET CAMERA FRAMES LIST
	useGetCameraFramesListQuery,
	// GET SYSTEM INTEGRATOR DETAILS
	useGetSystemIntegratorDetailsQuery,
	// GET EXCEL VALIDATIONS
	useGetExcelValidationsQuery,
	// GET VEHICLES LIST
	useGetVehiclesListQuery,
	// UPDATE VEHICLE
	useUpdateVehicleMutation
} = settings;
