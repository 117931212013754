import { Button, Link } from "@mui/material";
import { LoadingButtonProps } from "@mui/lab";
import React, { FC, ReactNode, useMemo } from "react";

import { useAppSelector } from "../../redux";
import { SecureLoginTooltip } from "../SecureLoginTooltip";

interface ISecureLoginAccessButtonProps extends LoadingButtonProps {
	tooltipAction: () => void;
}

const SecureLoginAccessButton: FC<ISecureLoginAccessButtonProps> = ({ tooltipAction, ...props }) => {
	const authState = useAppSelector((state) => state.auth);

	const TooltipTitle = useMemo<ReactNode>(() => {
		if (authState.user.secure_login_enabled_society && !authState.user.secure_login_enabled_user) {
			return "You don't have access";
		}

		return <Link onClick={tooltipAction}>Click here to login securely to view details</Link>;
	}, [authState.user.secure_login_enabled_society, authState.user.secure_login_enabled_user, tooltipAction]);

	if (
		(authState.user.secure_login_enabled_society || authState.user.secure_login_enabled_user) &&
		authState.user.login_type !== "secure"
	) {
		return (
			<SecureLoginTooltip title={TooltipTitle}>
				<span>
					<Button disabled {...props}></Button>
				</span>
			</SecureLoginTooltip>
		);
	}

	return <Button {...props} />;
};

export default SecureLoginAccessButton;
