import moment from "moment";

export const convertToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split(" ")
		.map((word) => word.replace(word[0], word[0]?.toUpperCase()))
		.join(" ");
};

export const convertSnakeToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split("_")
		.map((word) => word.replace(word[0], word[0]?.toUpperCase()))
		.join(" ");
};

export const convertKebabToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split("-")
		.map((word) => word.replace(word[0], word[0]?.toUpperCase()))
		.join(" ");
};

export const downloadBase64Image = (base64String: string, fileName: string) => {
	const downloadUrl = `data:image/png;base64,${base64String}`;
	const downloadLinkElement = document.createElement("a");

	downloadLinkElement.href = downloadUrl;
	downloadLinkElement.download = fileName;

	document.body.appendChild(downloadLinkElement);
	downloadLinkElement.click();

	URL.revokeObjectURL(downloadUrl);
	downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
};

export const downloadImageFromUrl = (imageUrl: string, fileName: string) => {
	const downloadLinkElement = document.createElement("a");

	downloadLinkElement.href = imageUrl;
	downloadLinkElement.download = fileName;

	document.body.appendChild(downloadLinkElement);
	downloadLinkElement.click();

	downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
};

export const getFormattedDuration = (durationSeconds: number): string => {
	let durationMinutes = 0;

	if (durationSeconds > 0) {
		durationMinutes = durationSeconds / 60;
	}

	const durationString: string[] = [];
	const duration = moment.duration(durationMinutes, "minutes");

	if (!duration || duration.toISOString() === "P0D") return "0 minutes";

	if (duration.years() >= 1 && durationString.length < 2) {
		const years = Math.floor(duration.years());
		durationString.push(`${years} years${years > 1 ? "s" : ""}`);
	}

	if (duration.months() >= 1 && durationString.length < 2) {
		const months = Math.floor(duration.months());
		durationString.push(`${months} month${months > 1 ? "s" : ""}`);
	}

	if (duration.days() >= 1 && durationString.length < 2) {
		const days = Math.floor(duration.days());
		durationString.push(`${days} day${days > 1 ? "s" : ""}`);
	}

	if (duration.hours() >= 1 && durationString.length < 2) {
		const hours = Math.floor(duration.hours());
		durationString.push(`${hours} hour${hours > 1 ? "s" : ""}`);
	}

	if (duration.minutes() >= 1 && durationString.length < 2) {
		const minutes = Math.floor(duration.minutes());
		durationString.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
	}

	if (duration.seconds() >= 1 && durationString.length < 2) {
		const seconds = Math.floor(duration.seconds());
		durationString.push(`${seconds} second${seconds > 1 ? "s" : ""}`);
	}

	if (durationString.length <= 0) return "0 minutes";

	return durationString.join(", ");
};

export const downloadFile = (fileBlob: Blob, fileName: string) => {
	const downloadUrl = URL.createObjectURL(fileBlob);
	const downloadLinkElement = document.createElement("a");

	downloadLinkElement.href = downloadUrl;
	downloadLinkElement.download = fileName;

	document.body.appendChild(downloadLinkElement);
	downloadLinkElement.click();

	URL.revokeObjectURL(downloadUrl);
	downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
};
