import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const DeviceStatusTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#D7D1FF",
		color: "var(--color-text-primary)",
		fontSize: 12,
		boxShadow: theme.shadows[2],
		fontWeight: 400,
		textAlign: "center",
		maxWidth: 200
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: "#D7D1FF"
	}
}));

export default DeviceStatusTooltip;
