import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRowHeightParams,
	GridRenderCellParams,
	GridRowSelectionModel,
	GridValueFormatterParams
} from "@mui/x-data-grid";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import React, {
	ChangeEvent,
	FC,
	FormEvent,
	MouseEvent,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	ClickAwayListener,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	Menu,
	MenuItem,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {
	ROWS_PER_PAGE,
	VEHICLE_TYPE_E,
	TIME_FILTER_FORMAT,
	VEHICLE_TYPES_LIST,
	MEMBER_OWNER_TYPE_NAME,
	TENANT_OWNER_TYPE_NAME
} from "../../../utils/constants";
import {
	CombinedRowType,
	IUploadResponse,
	IUploadResponseData,
	IVehicleOwnerDetails,
	IAuthorizedListRowDetails,
	IUploadResponseWhitelistDetails,
	IGetVehicleOwnersListRequestData,
	IUploadAuthorizedVehiclesExcelResponseData,
	IUploadAuthorizedVehicleExcelOwnerDetails,
	IUploadAuthorizedVehicleExcelVehicleDetails
} from "../../../types";
import {
	useGetVehicleOwnersListQuery,
	useGetAuthorizedVehicleCountsQuery,
	useUploadAuthorizedVehiclesDataMutation,
	useDeleteVehicleOwnersMutation
} from "../../../redux/reducers/dataManagement.reducer";
import "./authorizedList.css";
import { useAppDispatch, useAppSelector } from "../../../redux";
import TablePagination from "../../../components/TablePagination";
import { convertSnakeToTitleCase } from "../../../utils/commonUtils";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import downloadServices from "../../../redux/services/download.services";
import NewUploadDataDialog from "../../../components/NewUploadDataDialog";
import AuthorizedMemberDetailsDialog from "./AuthorizedMemberDetailsDialog";
import { useGetDevicesListQuery, useGetOwnerTypesQuery } from "../../../redux/reducers/settings.reducer";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import { CustomAutocompleteInput, CustomAutocompletePopper } from "../../../components/CustomAutocomplete";
import { SecureLoginTooltip } from "../../../components/SecureLoginTooltip";
import SecureLoginAccessButton from "../../../components/SecureLoginAccessButton";
import { openSecureLoginDialog } from "../../../redux/reducers/secureLoginDialog.reducer";

const AuthorizedListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const AuthorizedList: FC = () => {
	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const searchInputRef = useRef<HTMLInputElement>(null);
	const devicesDropdownRef = useRef<HTMLDivElement>(null);

	const [searchInput, setSearchInput] = useState<string>("");
	const [showAuthorizedMemberDetailsDialog, setShowAuthorizedMemberDetailsDialog] = useState<boolean>(false);
	const [selectedMemberDetails, setSelectedMemberDetails] = useState<IAuthorizedListRowDetails | null>(null);
	const [showUploadDataDialog, setShowUploadDataDialog] = useState<boolean>(false);
	const [uploadDataLoading, setUploadDataLoading] = useState<boolean>(false);
	const [showDownloadButtonLoader, setShowDownloadButtonLoader] = useState<boolean>(false);
	const [rowSelectionEnabled, setRowSelectionEnabled] = useState<boolean>(false);
	const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

	const [selectedDevices, setSelectedDevices] = useState<number[]>([]);
	const [devicesDropdownOpen, setDevicesDropdownOpen] = useState<boolean>(false);

	const [filters, setFilters] = useState<IGetVehicleOwnersListRequestData>({
		page: 1,
		auth_type: "whitelist"
	});

	// TABLE RENDERER STATES
	// VEHICLE TYPE DROPDOWN
	const [vehicleTypeDropdownAnchorElement, setVehicleTypeDropdownAnchorElement] = useState<HTMLElement | null>(null);
	const openVehicleTypeDropdown = Boolean(vehicleTypeDropdownAnchorElement);

	// OWNER TYPE DROPDOWN
	const [ownerTypeDropdownAnchorElement, setOwnerTypeDropdownAnchorElement] = useState<HTMLElement | null>(null);
	const openOwnerTypeDropdown = Boolean(ownerTypeDropdownAnchorElement);

	// APIS
	const { data: getOwnerTypesListResponse } = useGetOwnerTypesQuery();
	const {
		data: getVehicleOwnersResponse,
		isLoading: getVehicleOwnersLoading,
		isFetching: getVehicleOwnersFetching,
		refetch: refetchVehicleOwnersList
	} = useGetVehicleOwnersListQuery(filters);

	const [
		deleteVehicleOwners,
		{
			isLoading: deleteVehicleOwnersLoading,
			isSuccess: deleteVehicleOwnersSuccess,
			reset: resetDeleteVehicleOwnersState
		}
	] = useDeleteVehicleOwnersMutation();

	const [uploadAuthorizedVehiclesExcel] = useUploadAuthorizedVehiclesDataMutation();

	const { data: authorizedVehicleCounts, refetch: refetchAuthorizedVehicleCounts } =
		useGetAuthorizedVehicleCountsQuery();

	const { data: getDevicesListResponse } = useGetDevicesListQuery({});
	const devicesList = useMemo(
		() => (getDevicesListResponse ? getDevicesListResponse.results : []),
		[getDevicesListResponse]
	);

	const ownerTypesList = getOwnerTypesListResponse ? getOwnerTypesListResponse.results : [];

	const memberOwnerTypeId = ownerTypesList.find((item) => item.type_of === MEMBER_OWNER_TYPE_NAME)?.id ?? -1;
	const tenantOwnerTypeId = ownerTypesList.find((item) => item.type_of === TENANT_OWNER_TYPE_NAME)?.id ?? -1;

	const AllDevicesSelected = useMemo<"none" | "all" | "partial">(() => {
		if (selectedDevices.length === devicesList.length) return "all";
		if (selectedDevices.length === 0) return "none";
		return "partial";
	}, [devicesList.length, selectedDevices.length]);

	const refetchAllData = useCallback(() => {
		refetchVehicleOwnersList();
		refetchAuthorizedVehicleCounts();
	}, [refetchAuthorizedVehicleCounts, refetchVehicleOwnersList]);

	function handleChangeSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setSearchInput(event.target.value);
	}

	function handleClearSearchInput() {
		setSearchInput("");
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };
			delete updatedFilters.search;
			updatedFilters.page = 1;
			return updatedFilters;
		});

		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}

	function handleOpenAuthorizedMemberDetailsDialog(memberDetails: IAuthorizedListRowDetails | null) {
		setSelectedMemberDetails(memberDetails);
		setShowAuthorizedMemberDetailsDialog(true);
	}

	function handleCloseAuthorizedMemberDetailsDialog() {
		setShowAuthorizedMemberDetailsDialog(false);
		setSelectedMemberDetails(null);
		refetchAllData();
	}

	function handleOpenUploadDataDialog() {
		setShowUploadDataDialog(true);
	}

	function handleCloseUploadDataDialog() {
		setShowUploadDataDialog(false);
		refetchAllData();
	}

	function handleSearchAuthorizedListVehicle(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (searchInput) updatedFilters.search = searchInput;
			else delete updatedFilters.search;

			updatedFilters.page = 1;

			return updatedFilters;
		});
	}

	function handleDownloadButtonClick() {
		setShowDownloadButtonLoader(true);

		downloadServices
			.handleDownloadAuthorizedList()
			.catch((error) => console.error("DOWNLOAD AUTHORIZED LIST FAILED ::", error))
			.finally(() => setShowDownloadButtonLoader(false));
	}

	function handleUploadAuthorizedListDetails(
		rowsData: CombinedRowType[],
		isCustomFlatNumber: boolean,
		devices: number[],
		isVerified?: boolean
	): Promise<IUploadResponse> {
		return new Promise((resolve) => {
			setUploadDataLoading(true);

			// const uploadData: IUploadAuthorizedVehicleExcelDetails[] = [];
			const uploadData: IUploadAuthorizedVehicleExcelOwnerDetails[] = [];

			for (const rowDetails of rowsData) {
				if (rowDetails.is_deleted) continue;

				if (rowDetails.errorFields && Object.keys(rowDetails.errorFields).length > 0) {
					continue;
				}

				const vehicleTypeDetails = VEHICLE_TYPES_LIST.find(
					(vehicleTypeItem) => vehicleTypeItem.name.toLowerCase() === rowDetails["Vehicle Type"].toLowerCase()
				);

				const ownerTypeDetails = ownerTypesList.find(
					(ownerTypeItem) => ownerTypeItem.type_of.toLowerCase() === rowDetails["Owner Type"].toLowerCase()
				);

				const existingOwnerDataIndex = uploadData.findIndex(
					(item) =>
						item.phone_number === rowDetails["Contact Number"] &&
						item.flat_number === rowDetails["Flat Number"] &&
						item.name === rowDetails["Vehicle Owner Name"]
				);

				const uploadDataItem: IUploadAuthorizedVehicleExcelOwnerDetails =
					existingOwnerDataIndex >= 0
						? { ...uploadData[existingOwnerDataIndex] }
						: {
								flat_number: rowDetails["Flat Number"] ? rowDetails["Flat Number"] : null,
								is_custom_flat_number: isCustomFlatNumber,
								name: rowDetails["Vehicle Owner Name"] ? rowDetails["Vehicle Owner Name"] : null,
								phone_number: rowDetails["Contact Number"] ? rowDetails["Contact Number"] : null,
								owner_type: ownerTypeDetails?.id ?? 0,
								is_valid: false,
								vehicle: []
						  };

				const uploadDataItemVehicleDetails: IUploadAuthorizedVehicleExcelVehicleDetails = {
					number_plate: rowDetails["Number Plate"],
					vehicle_type: vehicleTypeDetails?.id ?? VEHICLE_TYPE_E.FOUR_WHEELER,
					auth_type: "whitelist",
					is_valid: false
				};

				if (ownerTypeDetails && ownerTypeDetails.has_expiry) {
					const validityStartDate = moment(rowDetails["Validity Start Date"]);
					const validityEndDate = moment(rowDetails["Validity End Date"]);

					const validityStartDateString = validityStartDate.format(TIME_FILTER_FORMAT);
					const validityEndDateString = validityEndDate.format(TIME_FILTER_FORMAT);

					const isValid =
						validityStartDate.isSameOrBefore(moment(), "day") && validityEndDate.isSameOrAfter(moment(), "day");

					uploadDataItem.validity_start_date = validityStartDateString;
					uploadDataItem.validity_end_date = validityEndDateString;
					uploadDataItem.is_valid = isValid;

					uploadDataItemVehicleDetails.validity_start_date = validityStartDateString;
					uploadDataItemVehicleDetails.validity_end_date = validityEndDateString;
					uploadDataItemVehicleDetails.is_valid = isValid;
				}

				if (uploadDataItemVehicleDetails.number_plate) {
					uploadDataItem.vehicle.push(uploadDataItemVehicleDetails);
				}

				if (existingOwnerDataIndex >= 0) {
					uploadData[existingOwnerDataIndex] = uploadDataItem;
				} else {
					uploadData.push(uploadDataItem);
				}

				// const uploadDataItem: IUploadAuthorizedVehicleExcelDetails = {
				// 	number_plate: rowDetails["Number Plate"],
				// 	vehicle_type: vehicleTypeDetails?.id ?? VEHICLE_TYPE_E.FOUR_WHEELER,
				// 	auth_type: "whitelist",
				// 	is_valid: true,
				// 	owner: {
				// 		flat_number: rowDetails["Flat Number"],
				// 		is_custom_flat_number: isCustomFlatNumber,
				// 		name: rowDetails["Vehicle Owner Name"],
				// 		phone_number: rowDetails["Contact Number"],
				// 		owner_type: ownerTypeDetails?.id ?? 0,
				// 		is_valid: true
				// 	}
				// };

				// if (ownerTypeDetails && ownerTypeDetails.has_expiry) {
				// 	const validityStartDate = moment(rowDetails["Validity Start Date"]).format(TIME_FILTER_FORMAT);
				// 	const validityEndDate = moment(rowDetails["Validity End Date"]).format(TIME_FILTER_FORMAT);

				// 	uploadDataItem.validity_start_date = validityStartDate;
				// 	uploadDataItem.validity_end_date = validityEndDate;

				// 	uploadDataItem.owner.validity_start_date = validityStartDate;
				// 	uploadDataItem.owner.validity_end_date = validityEndDate;
				// }

				// uploadData.push(uploadDataItem);
			}

			if (uploadData.length <= 0) {
				resolve({
					data: [],
					message: ""
				});
				return;
			}

			uploadAuthorizedVehiclesExcel({
				data: uploadData,
				is_verified: isVerified ?? false,
				is_custom_flat_number: isCustomFlatNumber,
				device: devices
			})
				.unwrap()
				.then((response) => {
					resolve({
						data: [],
						message: response.message
					});
					dispatch(
						showFeedbackNotification({
							message: response.message,
							severity: "success"
						})
					);
				})
				.catch((error) => {
					if (!error.data) {
						resolve({
							data: [],
							message: "Something went wrong"
						});
						return;
					}

					const errorData = error.data as IUploadAuthorizedVehiclesExcelResponseData;

					dispatch(
						showFeedbackNotification({
							message: errorData.message,
							severity: "error"
						})
					);

					const returnData: IUploadResponseWhitelistDetails[] = [];

					if (errorData.data) {
						/**
						 * GET ALL THE ROWS WITH ERRORS (ROW OBJECTS THAT HAVE THE database_data KEY)
						 */
						const filteredErrorData = errorData.data.filter(
							(item) =>
								(!!item.database_data && Object.keys(item.database_data).length > 0) ||
								(!!item.excel_data && Object.keys(item.excel_data).length > 0)
						);

						for (const errorDataItem of filteredErrorData) {
							if (!errorDataItem.remarks) continue;

							const responseErrorData = errorDataItem.database_data ?? errorDataItem.excel_data ?? {};

							if (errorDataItem.remarks === "Owner Duplicate") {
								const dbOwnerData = {
									name: responseErrorData.owner?.name ?? "",
									flatNumber: responseErrorData.owner?.flat_number ?? "",
									ownerType: responseErrorData.owner?.owner_type ?? -1,
									contactNumber: responseErrorData.owner?.phone_number ?? "",
									validityStartDate:
										responseErrorData.owner && responseErrorData.owner.validity_start_date
											? moment(responseErrorData.owner.validity_start_date)
											: null,
									validityEndDate:
										responseErrorData.owner && responseErrorData.owner.validity_end_date
											? moment(responseErrorData.owner.validity_end_date)
											: null
								};

								const excelOwnerData = {
									name: errorDataItem.name,
									flatNumber: errorDataItem.flat_number ?? "",
									ownerType: errorDataItem.owner_type ?? -1,
									contactNumber: errorDataItem.phone_number ?? "",
									validityStartDate: errorDataItem.validity_start_date
										? moment(errorDataItem.validity_start_date)
										: null,
									validityEndDate: errorDataItem.validity_end_date ? moment(errorDataItem.validity_end_date) : null
								};

								if (JSON.stringify(dbOwnerData) === JSON.stringify(excelOwnerData)) {
									continue;
								}

								const ownerTypeDetails = ownerTypesList.find(
									(ownerTypeItem) => ownerTypeItem.id === (responseErrorData.owner?.id ?? -1)
								);

								let validityStartDate: Date | null = null;
								let validityEndDate: Date | null = null;

								if (
									responseErrorData.owner &&
									responseErrorData.owner.validity_start_date &&
									responseErrorData.owner.validity_end_date
								) {
									validityStartDate = new Date(responseErrorData.owner.validity_start_date);
									validityEndDate = new Date(responseErrorData.owner.validity_end_date);
								}

								const returnDataItem: IUploadResponseWhitelistDetails = {
									"Vehicle Owner Name": responseErrorData.owner?.name ?? "",
									"Flat Number": responseErrorData.owner?.flat_number ?? "",
									"Number Plate": "",
									"Vehicle Type": "",
									"Owner Type": ownerTypeDetails?.type_of ?? "",
									"Contact Number": responseErrorData.owner?.phone_number ?? "",
									"Validity Start Date": validityStartDate,
									"Validity End Date": validityEndDate,
									id: 0,
									sr_no: 0,
									is_deleted: false,
									remarks: errorDataItem.remarks,
									errorFields: {}
								};

								if (returnDataItem.errorFields) {
									if (responseErrorData.owner && responseErrorData.owner.remarks) {
										returnDataItem.errorFields["Vehicle Owner Name"] = [responseErrorData.owner.remarks];
									} else {
										returnDataItem.errorFields["Vehicle Owner Name"] = ["This owner already exists"];
									}
								}

								// if (
								// 	responseErrorData.owner &&
								// 	responseErrorData.owner.remarks &&
								// 	returnDataItem.errorFields
								// ) {
								// 	returnDataItem.errorFields["Vehicle Owner Name"] = [responseErrorData.owner.remarks];
								// }

								returnData.push(returnDataItem);
							} else if (
								errorDataItem.remarks === "Vehicle Duplicates" ||
								errorDataItem.remarks === "Vehicle and Owner Both Duplicate"
							) {
								const ownerTypeDetails = ownerTypesList.find(
									(ownerTypeItem) => ownerTypeItem.id === responseErrorData.owner?.owner_type ?? -1
								);

								let validityStartDate: Date | null = null;
								let validityEndDate: Date | null = null;

								if (
									responseErrorData.owner &&
									responseErrorData.owner.validity_start_date &&
									responseErrorData.owner.validity_end_date
								) {
									validityStartDate = new Date(responseErrorData.owner.validity_start_date);
									validityEndDate = new Date(responseErrorData.owner.validity_end_date);
								}

								if (responseErrorData.vehicle) {
									for (const vehicleItem of responseErrorData.vehicle) {
										const vehicleTypeDetails = VEHICLE_TYPES_LIST.find(
											(vehicleTypeItem) => vehicleTypeItem.id === vehicleItem.vehicle_type ?? -1
										);

										const returnDataItem: IUploadResponseWhitelistDetails = {
											"Vehicle Owner Name": responseErrorData.owner?.name ?? "",
											"Flat Number": responseErrorData.owner?.flat_number ?? "",
											"Number Plate": vehicleItem.number_plate ?? "",
											"Vehicle Type": vehicleTypeDetails?.name ?? "",
											"Owner Type": ownerTypeDetails?.type_of ?? "",
											"Contact Number": responseErrorData.owner?.phone_number ?? "",
											"Validity Start Date": validityStartDate,
											"Validity End Date": validityEndDate,
											id: 0,
											sr_no: 0,
											is_deleted: false,
											remarks: errorDataItem.remarks,
											errorFields: {}
										};

										if (returnDataItem.errorFields) {
											if (vehicleItem.remarks) {
												returnDataItem.errorFields["Number Plate"] = [vehicleItem.remarks];
											} else {
												returnDataItem.errorFields["Number Plate"] = ["Vehicle with this number already exists"];
											}

											if (responseErrorData.owner && responseErrorData.owner.remarks) {
												returnDataItem.errorFields["Vehicle Owner Name"] = [responseErrorData.owner.remarks];
											} else if (errorDataItem.remarks === "Vehicle and Owner Both Duplicate") {
												returnDataItem.errorFields["Vehicle Owner Name"] = ["This owner already exists"];
											}
										}

										// if (vehicleItem.remarks && returnDataItem.errorFields) {
										// 	returnDataItem.errorFields["Number Plate"] = [vehicleItem.remarks];
										// }

										// if (
										// 	responseErrorData.owner &&
										// 	responseErrorData.owner.remarks &&
										// 	returnDataItem.errorFields
										// ) {
										// 	returnDataItem.errorFields["Vehicle Owner Name"] = [responseErrorData.owner.remarks];
										// }

										returnData.push(returnDataItem);
									}
								}
							}
						}
					}

					resolve({
						data: returnData as IUploadResponseData[],
						message: errorData.message
					});
				})
				.finally(() => {
					setUploadDataLoading(false);
				});
		});
	}

	function handlePageChange(event: GridPaginationModel) {
		setFilters((currentFiltersState) => ({
			...currentFiltersState,
			page: event.page + 1
		}));
	}

	function handleOpenDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(true);
	}

	function handleCloseDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(false);
	}

	function handleEnableRowSelection() {
		setRowSelectionEnabled(true);
	}

	function handleDisableRowSelection() {
		setRowSelectionEnabled(false);
		setSelectedRowIds([]);
	}

	function handleSelectRows(updatedValues: GridRowSelectionModel) {
		setSelectedRowIds(updatedValues as number[]);
	}

	function handleDeleteSelectedVehicleOwners() {
		deleteVehicleOwners(selectedRowIds);
	}

	function handleChangeSelectedDevices(updatedValue: number[]) {
		setSelectedDevices(updatedValue);
	}

	function handleApplyDevicesFilter() {
		setFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			updatedFilters.page = 1;
			if (selectedDevices.length === 0 || selectedDevices.length === devicesList.length) {
				delete updatedFilters.device_ids;
			} else {
				updatedFilters.device_ids = selectedDevices;
			}

			return updatedFilters;
		});

		handleCloseDevicesDropdown();
	}

	function handleOpenSecureLoginDialog() {
		dispatch(openSecureLoginDialog());
	}

	useEffect(() => {
		if (deleteVehicleOwnersSuccess) {
			resetDeleteVehicleOwnersState();
			handleCloseDeleteConfirmationDialog();
			handleDisableRowSelection();
			refetchAllData();
		}
	}, [deleteVehicleOwnersSuccess, refetchAllData, resetDeleteVehicleOwnersState]);

	useEffect(() => {
		setSelectedDevices((currentSelectedDevices) => {
			if (currentSelectedDevices.length !== devicesList.length) return devicesList.map((item) => item.id);
			return currentSelectedDevices;
		});
	}, [devicesList]);

	// TABLE RENDERER FUNCTIONS
	// VEHICLE TYPE DROPDOWN
	function handleOpenVehicleTypeDropdown(event: MouseEvent<HTMLDivElement>) {
		setVehicleTypeDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseVehicleTypeDropdown() {
		setVehicleTypeDropdownAnchorElement(null);
	}

	function handleChangeSelectedVehicleType(updatedValue: number) {
		handleCloseVehicleTypeDropdown();
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (updatedValue >= 0) updatedFilters.vehicle_type = updatedValue;
			else delete updatedFilters.vehicle_type;

			updatedFilters.page = 1;

			return updatedFilters;
		});
	}

	function renderVehicleTypeDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="vehicle-type-dropdown"
					aria-controls={openVehicleTypeDropdown ? "vehicle-type-dropdown-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={openVehicleTypeDropdown ? "true" : undefined}
					onClick={handleOpenVehicleTypeDropdown}
				>
					<Typography variant="subtitle2" textTransform="capitalize">
						{typeof filters.vehicle_type === "number"
							? filters.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER
								? "Four Wheeler"
								: "Two Wheeler"
							: "Vehicle Type"}
					</Typography>
					{openVehicleTypeDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="vehicle-type-dropdown-menu"
					anchorEl={vehicleTypeDropdownAnchorElement}
					open={openVehicleTypeDropdown}
					onClose={handleCloseVehicleTypeDropdown}
					MenuListProps={{ "aria-labelledby": "vehicle-type-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedVehicleType(-1)} sx={{ minWidth: 130 }}>
						<em>All</em>
					</MenuItem>

					{VEHICLE_TYPES_LIST.map((vehicleTypeItem) => (
						<MenuItem key={vehicleTypeItem.id} onClick={() => handleChangeSelectedVehicleType(vehicleTypeItem.id)}>
							{vehicleTypeItem.name}
						</MenuItem>
					))}
				</Menu>
			</Box>
		);
	}

	// OWNER TYPE DROPDOWN
	function getOwnerTypeName(ownerTypeId: number): string {
		const ownertTypeDetails = ownerTypesList.find((ownerTypeItem) => ownerTypeItem.id === ownerTypeId);
		return ownertTypeDetails ? convertSnakeToTitleCase(ownertTypeDetails.type_of) : "";
	}

	function handleOpenOwnerTypeDropdown(event: MouseEvent<HTMLDivElement>) {
		setOwnerTypeDropdownAnchorElement(event.currentTarget);
	}

	function handleCloseOwnerTypeDropdown() {
		setOwnerTypeDropdownAnchorElement(null);
	}

	function handleChangeSelectedOwnerType(updatedValue: number) {
		handleCloseOwnerTypeDropdown();
		setFilters((currentFiltersState) => {
			const updatedFilters = { ...currentFiltersState };

			if (updatedValue >= 0) updatedFilters.owner_type = updatedValue;
			else delete updatedFilters.owner_type;

			updatedFilters.page = 1;

			return updatedFilters;
		});
	}

	function renderOwnerTypeDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					id="owner-type-dropdown"
					aria-controls={openOwnerTypeDropdown ? "owner-type-dropdown-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={openOwnerTypeDropdown ? "true" : undefined}
					onClick={handleOpenOwnerTypeDropdown}
				>
					<Typography variant="subtitle2" textTransform="capitalize">
						{filters.owner_type ? getOwnerTypeName(filters.owner_type) : "Owner Type"}
					</Typography>
					{openOwnerTypeDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<Menu
					id="owner-type-dropdown-menu"
					anchorEl={ownerTypeDropdownAnchorElement}
					open={openOwnerTypeDropdown}
					onClose={handleCloseOwnerTypeDropdown}
					MenuListProps={{ "aria-labelledby": "owner-type-dropdown" }}
					sx={{ width: "100%" }}
				>
					<MenuItem onClick={() => handleChangeSelectedOwnerType(-1)} sx={{ minWidth: 130 }}>
						<em>All</em>
					</MenuItem>

					{ownerTypesList
						? ownerTypesList.map((ownerTypeItem) => (
								<MenuItem key={ownerTypeItem.id} onClick={() => handleChangeSelectedOwnerType(ownerTypeItem.id)}>
									{convertSnakeToTitleCase(ownerTypeItem.type_of)}
								</MenuItem>
						  ))
						: null}
				</Menu>
			</Box>
		);
	}

	// DEVICES DROPDOWN
	function handleOpenDevicesDropdown() {
		setDevicesDropdownOpen(true);
	}

	function handleCloseDevicesDropdown() {
		setDevicesDropdownOpen(false);
	}

	function renderDevicesDropdown() {
		return (
			<Box>
				<Box
					component="div"
					className="table-column-header-dropdown-wrapper"
					ref={devicesDropdownRef}
					onClick={handleOpenDevicesDropdown}
					aria-describedby="devices-dropdown-menu"
				>
					<Typography variant="subtitle2">Devices</Typography>
					{devicesDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</Box>

				<ClickAwayListener onClickAway={handleCloseDevicesDropdown}>
					<CustomAutocompletePopper
						id="devices-dropdown-menu"
						open={devicesDropdownOpen}
						anchorEl={devicesDropdownRef.current}
						placement="bottom-start"
					>
						<Autocomplete
							open
							multiple
							disableCloseOnSelect
							options={devicesList}
							getOptionLabel={(option) => option.device_name}
							getOptionKey={(option) => option.id}
							onClose={(_event, reason) => {
								if (reason === "escape") {
									handleCloseDevicesDropdown();
								}
							}}
							value={devicesList.filter((item) => selectedDevices.includes(item.id))}
							onChange={(_, updatedValues) => handleChangeSelectedDevices(updatedValues.map((item) => item.id))}
							componentsProps={{
								paper: { sx: { borderRadius: 0, borderBottomRightRadius: 6, borderBottomLeftRadius: 6 } }
							}}
							renderInput={(params) => (
								<CustomAutocompleteInput
									ref={params.InputProps.ref}
									inputProps={params.inputProps}
									autoFocus
									placeholder="Search"
									fullWidth
								/>
							)}
							renderTags={() => <></>}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ gap: "0.5rem" }}>
									{selected ? (
										<CheckBoxIcon color="primary" sx={{ marginY: "0.25rem" }} />
									) : (
										<CheckBoxOutlineBlankIcon color="primary" sx={{ marginY: "0.25rem" }} />
									)}

									{option.device_name}
								</li>
							)}
							PaperComponent={(props) => {
								const { children, ...restProps } = props;
								return (
									<Paper {...restProps}>
										<Box
											onMouseDown={(event) => event.preventDefault()}
											sx={{ paddingX: "1.25rem", paddingY: "0.25rem", width: "100%" }}
										>
											<FormControlLabel
												label="Select All"
												sx={{ width: "100%" }}
												control={
													<Checkbox
														color="primary"
														checked={AllDevicesSelected === "all"}
														indeterminate={AllDevicesSelected === "partial"}
													/>
												}
												onClick={(event) => {
													event.preventDefault();
													if (AllDevicesSelected === "none" || AllDevicesSelected === "partial") {
														handleChangeSelectedDevices(devicesList.map((item) => item.id));
													} else if (AllDevicesSelected === "all") {
														handleChangeSelectedDevices([]);
													}
												}}
											/>
										</Box>

										<Divider />
										<Box sx={{ maxHeight: "200px", overflow: "auto" }}>{children}</Box>
										<Divider />

										<Box
											onMouseDown={(event) => event.preventDefault()}
											sx={{
												paddingX: "1.25rem",
												paddingY: "0.25rem",
												width: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-end"
											}}
										>
											<Button onClick={handleApplyDevicesFilter}>Apply</Button>
										</Box>
									</Paper>
								);
							}}
						/>
					</CustomAutocompletePopper>
				</ClickAwayListener>
			</Box>
		);
	}

	function renderMaskedDataColumnHeader(headerName: string) {
		const TooltipTitle: ReactNode =
			authState.user.secure_login_enabled_society && !authState.user.secure_login_enabled_user ? (
				"You don't have access"
			) : (
				<Link onClick={handleOpenSecureLoginDialog}>Click here to login securely to view details</Link>
			);

		return (
			<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", width: "100%", height: "100%" }}>
				<Typography variant="subtitle2" textTransform="capitalize">
					{headerName}
				</Typography>

				{(authState.user.secure_login_enabled_society || authState.user.secure_login_enabled_user) &&
				authState.user.login_type !== "secure" ? (
					<SecureLoginTooltip title={TooltipTitle}>
						<LockIcon htmlColor="#0000008F" sx={{ fontSize: "16px" }} />
					</SecureLoginTooltip>
				) : null}
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{
			field: "sr_no",
			headerName: "Sr. No.",
			width: 65,
			sortable: false
		},
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			sortable: false,
			renderHeader: () => renderMaskedDataColumnHeader("Name")
		},
		{
			field: "flat_no",
			headerName: "Unit No.",
			flex: 1,
			minWidth: 75,
			sortable: false
		},
		{
			field: "vehicle_number",
			headerName: "Vehicle Number",
			flex: 1,
			minWidth: 150,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IAuthorizedListRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
					{params.row.vehicles.map((vehicleItem) => (
						<Box key={vehicleItem.id} sx={{ flex: 1, display: "flex", alignItems: "center" }}>
							<Typography variant="body2">{vehicleItem.vehicle_number}</Typography>
						</Box>
					))}
				</Box>
			)
		},
		{
			field: "vehicle_type",
			headerName: "Vehicle Type",
			flex: 1,
			minWidth: 130,
			sortable: false,
			renderHeader: renderVehicleTypeDropdown,
			renderCell: (params: GridRenderCellParams<IAuthorizedListRowDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
					{params.row.vehicles.map((vehicleItem) => (
						<Box key={vehicleItem.id} sx={{ flex: 1, display: "flex", alignItems: "center" }}>
							<Typography variant="body2">
								{vehicleItem.vehicle_type === VEHICLE_TYPE_E.FOUR_WHEELER ? "Four Wheeler" : "Two Wheeler"}
							</Typography>
						</Box>
					))}
				</Box>
			)
		},
		{
			field: "owner_type",
			headerName: "Owner Type",
			flex: 1,
			minWidth: 120,
			sortable: false,
			renderHeader: renderOwnerTypeDropdown
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 200,
			sortable: false,
			renderHeader: () => renderMaskedDataColumnHeader("Contact Number")
		},
		{
			field: "devices",
			headerName: "Devices",
			width: 100,
			sortable: false,
			renderHeader: renderDevicesDropdown,
			valueFormatter: (params: GridValueFormatterParams<number[]>) => (params.value ?? []).length
		},
		{
			field: "info",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IAuthorizedListRowDetails>) => (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
					<IconButton size="small" onClick={() => handleOpenAuthorizedMemberDetailsDialog(params.row)}>
						<InfoOutlinedIcon fontSize="inherit" sx={{ color: "#1e53af" }} />
					</IconButton>
				</Box>
			)
		}
	];

	function getAuthorizedListRowDetails(authorizedList: IVehicleOwnerDetails[]): IAuthorizedListRowDetails[] {
		const activePage = filters.page - 1;

		return authorizedList.map((vehicleOwnerItem, index) => {
			const ownerTypeDetails = ownerTypesList.find((ownerTypeItem) => ownerTypeItem.id === vehicleOwnerItem.owner_type);

			return {
				id: vehicleOwnerItem.id,
				sr_no: activePage * ROWS_PER_PAGE + index + 1,
				name: vehicleOwnerItem.name,
				flat_no: vehicleOwnerItem.flat_number,
				owner_type: ownerTypeDetails ? convertSnakeToTitleCase(ownerTypeDetails.type_of) : "",
				owner_type_id: vehicleOwnerItem.owner_type,
				contact_number: vehicleOwnerItem.phone_number,
				validity_start_date: vehicleOwnerItem.validity_start_date,
				validity_end_date: vehicleOwnerItem.validity_end_date,
				vehicles: vehicleOwnerItem.vehicles.map((vehicleItem) => ({
					id: vehicleItem.id,
					vehicle_number: vehicleItem.number_plate,
					vehicle_type: vehicleItem.vehicle_type
				})),
				is_custom_flat_number: vehicleOwnerItem.is_custom_flat_number,
				devices: vehicleOwnerItem.device
			};
		});
	}

	function getMembersVehiclesCount(): number {
		if (!authorizedVehicleCounts) return 0;

		const membersVehiclesCountDetails = authorizedVehicleCounts.owner_type_count.find(
			(item) => Number(item.owner_type) === memberOwnerTypeId
		);

		if (!membersVehiclesCountDetails) return 0;

		return membersVehiclesCountDetails.count;
	}

	function getTenantsVehiclesCount(): number {
		if (!authorizedVehicleCounts) return 0;

		const tenantsVehiclesCountDetails = authorizedVehicleCounts.owner_type_count.find(
			(item) => Number(item.owner_type) === tenantOwnerTypeId
		);

		if (!tenantsVehiclesCountDetails) return 0;

		return tenantsVehiclesCountDetails.count;
	}

	function getMiscVehiclesCount(): number {
		if (!authorizedVehicleCounts) return 0;

		const miscVehiclesCountsList = authorizedVehicleCounts.owner_type_count.filter(
			(item) => ![memberOwnerTypeId, tenantOwnerTypeId].includes(Number(item.owner_type))
		);

		return miscVehiclesCountsList.reduce((sum, item) => sum + item.count, 0);
	}

	return (
		<Box className="authorized-list-screen-wrapper">
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<Box className="authorized-list-data-card">
						<Box className="authorized-list-data-card-icon">
							<DirectionsCarFilledIcon color="success" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="authorized-list-data-card-details">
							<Typography variant="body2" color="#00000080">
								Registered Vehicles
							</Typography>

							<Typography fontWeight={600}>{authorizedVehicleCounts?.total_vehicle_count ?? 0}</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={3}>
					<Box className="authorized-list-data-card">
						<Box className="authorized-list-data-card-icon">
							<SupervisedUserCircleIcon color="success" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="authorized-list-data-card-details">
							<Typography variant="body2" color="#00000080">
								Members
							</Typography>

							<Typography fontWeight={600}>{getMembersVehiclesCount()}</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={3}>
					<Box className="authorized-list-data-card">
						<Box className="authorized-list-data-card-icon">
							<HowToRegIcon color="success" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="authorized-list-data-card-details">
							<Typography variant="body2" color="#00000080">
								Tenants
							</Typography>

							<Typography fontWeight={600}>{getTenantsVehiclesCount()}</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={3}>
					<Box className="authorized-list-data-card">
						<Box className="authorized-list-data-card-icon">
							<AssignmentTurnedInIcon color="success" sx={{ fontSize: "28px" }} />
						</Box>

						<Box className="authorized-list-data-card-details">
							<Typography variant="body2" color="#00000080">
								Miscellaneous
							</Typography>

							<Typography fontWeight={600}>{getMiscVehiclesCount()}</Typography>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Box className="authorized-list-wrapper">
				<Box className="authorized-list-header-wrapper">
					<Box className="authorized-list-header-actions-wrapper">
						<SecureLoginAccessButton
							variant="outlined"
							color="success"
							startIcon={<AddCircleIcon />}
							sx={{ paddingX: 2 }}
							onClick={() => handleOpenAuthorizedMemberDetailsDialog(null)}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Add
						</SecureLoginAccessButton>

						{rowSelectionEnabled ? (
							<Button
								variant="outlined"
								color="primary"
								// size="small"
								startIcon={<CancelIcon />}
								sx={{ paddingX: 2 }}
								onClick={handleDisableRowSelection}
							>
								Cancel
							</Button>
						) : (
							<SecureLoginAccessButton
								variant="outlined"
								color="primary"
								// size="small"
								startIcon={<CheckBoxIcon />}
								sx={{ paddingX: 2 }}
								onClick={handleEnableRowSelection}
								tooltipAction={handleOpenSecureLoginDialog}
							>
								Select
							</SecureLoginAccessButton>
						)}

						<SecureLoginAccessButton
							variant="outlined"
							color="error"
							// size="small"
							startIcon={<DeleteOutlineIcon />}
							sx={{ paddingX: 2 }}
							disabled={selectedRowIds.length <= 0}
							onClick={handleOpenDeleteConfirmationDialog}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Remove
						</SecureLoginAccessButton>
					</Box>

					<Box className="authorized-list-header-actions-wrapper">
						<Box component="form" noValidate onSubmit={handleSearchAuthorizedListVehicle}>
							<TextField
								size="small"
								onChange={handleChangeSearchInput}
								value={searchInput}
								placeholder="Search"
								inputRef={searchInputRef}
								sx={{ width: "200px" }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{filters.search ? (
												<IconButton edge="end" size="small" onClick={handleClearSearchInput}>
													<CloseIcon fontSize="inherit" />
												</IconButton>
											) : null}

											<IconButton type="submit" edge="end">
												<SearchIcon fontSize="small" />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Box>

						<SecureLoginAccessButton
							variant="outlined"
							color="primary"
							startIcon={<FileUploadIcon />}
							sx={{ paddingX: 3 }}
							onClick={handleOpenUploadDataDialog}
							tooltipAction={handleOpenSecureLoginDialog}
						>
							Upload
						</SecureLoginAccessButton>

						<LoadingButton
							disableElevation
							variant="contained"
							color="primary"
							startIcon={<DownloadIcon />}
							onClick={handleDownloadButtonClick}
							loading={showDownloadButtonLoader}
							loadingPosition="start"
						>
							Download
						</LoadingButton>
					</Box>
				</Box>

				<Box className="authorized-list-table-wrapper">
					<DataGrid
						columns={columnsData}
						rows={getAuthorizedListRowDetails(getVehicleOwnersResponse?.results ?? [])}
						getRowId={(item) => item.id}
						rowSelectionModel={selectedRowIds}
						checkboxSelection={rowSelectionEnabled}
						onRowSelectionModelChange={handleSelectRows}
						loading={getVehicleOwnersLoading || getVehicleOwnersFetching}
						disableColumnMenu
						disableRowSelectionOnClick
						density="compact"
						rowSpacingType="border"
						columnHeaderHeight={80}
						paginationMode="server"
						pageSizeOptions={[ROWS_PER_PAGE]}
						onPaginationModelChange={handlePageChange}
						rowCount={getVehicleOwnersResponse?.count ?? 0}
						paginationModel={{ page: filters.page ? filters.page - 1 : 0, pageSize: ROWS_PER_PAGE }}
						slots={{ pagination: AuthorizedListPagination }}
						slotProps={{ footer: { sx: { justifyContent: "center" } } }}
						hideFooter={(getVehicleOwnersResponse?.count ?? 0) <= ROWS_PER_PAGE}
						classes={{
							columnSeparator: "authorized-list-table-column-separator",
							columnHeader: "authorized-list-table-column-headers",
							cell: "authorized-list-table-cell"
						}}
						getRowHeight={({ densityFactor, model }: GridRowHeightParams) => {
							if (model.vehicles && model.vehicles.length > 1) {
								return 50 * model.vehicles.length * densityFactor;
							}

							return null;
						}}
					/>
				</Box>
			</Box>

			<AuthorizedMemberDetailsDialog
				open={showAuthorizedMemberDetailsDialog}
				memberDetails={selectedMemberDetails}
				onClose={handleCloseAuthorizedMemberDetailsDialog}
			/>

			{/* <UploadDataDialog
				open={showUploadDataDialog}
				onClose={handleCloseUploadDataDialog}
				onUploadButtonClick={handleUploadAuthorizedListDetails}
				sampleFileUrl={"/analytics/download/members_info_template"}
				sampleFileName={"AuthorizedListSample.xlsx"}
				// uploadButtonLoading={uploadAuthorizedListDataLoading}
			/> */}

			<NewUploadDataDialog
				open={showUploadDataDialog}
				sampleFileUrl="/analytics/download/members_info_template"
				uploadButtonLoading={uploadDataLoading}
				onClose={handleCloseUploadDataDialog}
				onUploadButtonClick={handleUploadAuthorizedListDetails}
			/>

			<ConfirmationDialog
				open={showDeleteConfirmationDialog}
				title="Delete Vehicle Owners"
				heading="Are you sure you want to delete selected vehicle owners?"
				onClose={handleCloseDeleteConfirmationDialog}
				onConfirm={handleDeleteSelectedVehicleOwners}
				color="error"
				confirmButtonLoading={deleteVehicleOwnersLoading}
				width="sm"
			/>
		</Box>
	);
};

export default AuthorizedList;
