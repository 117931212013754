import {
	Autocomplete,
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography
} from "@mui/material";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import React, { FC, useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {
	IAuthorizedListRowDetails,
	IAuthorizedVehicleDetails,
	ICreateVehicleOwnerVehiclesRequestData,
	IEditVehicleOwnerVehiclesRequestData,
	ISocietyConfigDetails
} from "../../../../types";
import {
	useAddVehicleOwnerMutation,
	useDeleteVehicleOwnerVehicleMutation,
	useEditVehicleOwnerMutation
} from "../../../../redux/reducers/dataManagement.reducer";
import {
	useGetDevicesListQuery,
	useGetExcelValidationsQuery,
	useGetOwnerTypesQuery,
	useGetSocietyConfigQuery
} from "../../../../redux/reducers/settings.reducer";
import { VEHICLE_TYPES_LIST } from "../../../../utils/constants";
import NumberInput from "../../../../components/NumberInput";
import { convertToTitleCase } from "../../../../utils/commonUtils";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import SecureLoginAccessButton from "../../../../components/SecureLoginAccessButton";
import { openSecureLoginDialog } from "../../../../redux/reducers/secureLoginDialog.reducer";

interface IAuthorizedMemberDetailsDialogProps {
	open: boolean;
	memberDetails: IAuthorizedListRowDetails | null;
	onClose: () => void;
}

type IAddedVehicleDetails = Omit<IAuthorizedVehicleDetails, "id">;

interface IAddedVehicleErrors {
	[index: number]: string;
}

interface IFlatDetails {
	key: string;
	building: string;
	flatNumber: string;
}

interface IFormFields {
	name: string;
	contact_number: string;
	flat_no: string;
	owner_type: number;
	validity_start_date?: Moment | null;
	validity_end_date?: Moment | null;
	devices: number[];
}

const AuthorizedMemberDetailsDialog: FC<IAuthorizedMemberDetailsDialogProps> = (props) => {
	const { open, memberDetails, onClose } = props;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const [existingVehiclesList, setExistingVehiclesList] = useState<IAuthorizedVehicleDetails[]>([]);
	const [addedVehiclesList, setAddedVehiclesList] = useState<IAddedVehicleDetails[]>([]);

	const [deletingVehicleIds, setDeletingVehicleIds] = useState<number[]>([]);
	const [deletingAddedVehicleIds, setDeletingAddedVehicleIds] = useState<number[]>([]);

	const [addedVehiclesError, setAddedVehiclesError] = useState<IAddedVehicleErrors>({});
	const [existingVehiclesError, setExistingVehiclesError] = useState<IAddedVehicleErrors>({});

	const [showValidityInputs, setShowValidityInputs] = useState<boolean>(false);

	const [customFlatSelectionEnabled, setCustomFlatSelectionEnabled] = useState<boolean>(false);
	const [apiError, setApiError] = useState<string>("");

	const ReadOnlyInput = useMemo<boolean>(
		() =>
			(authState.user.secure_login_enabled_society || authState.user.secure_login_enabled_user) &&
			authState.user.login_type !== "secure",
		[authState.user]
	);

	// APIS
	const { data: getOwnerTypesResponse } = useGetOwnerTypesQuery();
	const ownerTypesList = getOwnerTypesResponse?.results ?? [];

	const { data: excelValidations } = useGetExcelValidationsQuery();

	const { data: getSocietyConfigResponse } = useGetSocietyConfigQuery();
	const societyDetails: ISocietyConfigDetails | null =
		getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0
			? getSocietyConfigResponse.results[0]
			: null;

	const { data: getDevicesListResponse } = useGetDevicesListQuery({});
	const devicesList = getDevicesListResponse ? getDevicesListResponse.results : [];

	const [
		addVehicleOwner,
		{
			isSuccess: addVehicleOwnerSuccess,
			isLoading: addVehicleOwnerLoading,
			error: addVehicleOwnerError,
			reset: resetAddVehicleOwnerUpdatedState
		}
	] = useAddVehicleOwnerMutation();

	const [
		editVehicleOwner,
		{
			isSuccess: editVehicleOwnerSuccess,
			isLoading: editVehicleOwnerLoading,
			error: editVehicleOwnerError,
			reset: resetEditVehicleOwnerState
		}
	] = useEditVehicleOwnerMutation();

	const [deleteVehicleOwnerVehicles] = useDeleteVehicleOwnerVehicleMutation();

	const FlatsList = useMemo(() => {
		const flatsList: IFlatDetails[] = [];

		if (societyDetails) {
			for (const buildingDetails of societyDetails.building_config) {
				for (const buildingEntry of buildingDetails.entries) {
					if (buildingDetails.name === "Common Area") {
						flatsList.push({
							key: buildingEntry,
							building: buildingDetails.name,
							flatNumber: buildingEntry
						});
					} else {
						flatsList.push({
							key: `${buildingDetails.name}${buildingDetails.delimiter ?? "-"}${buildingEntry}`,
							building: buildingDetails.name,
							flatNumber: buildingEntry
						});
					}
				}
			}
		}

		return flatsList;
	}, [societyDetails]);

	const {
		control,
		reset,
		watch,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IFormFields>();

	const selectedOwnerType = watch("owner_type");

	// const memberOwnerTypeId =
	// 	ownerTypesList.find((ownerTypeItem) => ownerTypeItem.type_of === MEMBER_OWNER_TYPE_NAME)?.id ?? -1;

	// const tenantOwnerTypeId =
	// 	ownerTypesList.find((ownerTypeItem) => ownerTypeItem.type_of === TENANT_OWNER_TYPE_NAME)?.id ?? -1;

	function handleAddNewVehicle() {
		setAddedVehiclesList((currentVehiclesList) => {
			const updatedVehiclesList = [...currentVehiclesList];

			updatedVehiclesList.push({
				vehicle_number: "",
				vehicle_type: 0
			});

			return updatedVehiclesList;
		});
	}

	function handleChangeExistingVehicleType(index: number, updatedValue: number) {
		setExistingVehiclesList((currentVehiclesList) => {
			const updatedVehiclesList = [...currentVehiclesList];

			updatedVehiclesList[index] = {
				...updatedVehiclesList[index],
				vehicle_type: updatedValue
			};

			return updatedVehiclesList;
		});
	}

	function handleChangeExistingVehicleNumber(index: number, updatedValue: string) {
		setExistingVehiclesList((currentVehiclesList) => {
			const updatedVehiclesList = [...currentVehiclesList];

			updatedVehiclesList[index] = {
				...updatedVehiclesList[index],
				vehicle_number: updatedValue
			};

			return updatedVehiclesList;
		});
	}

	function handleRevertDeletedExistingVehicle(vehicleId: number) {
		setDeletingVehicleIds((currentVehicleIds) => {
			const updatedVehicleIds = currentVehicleIds.filter((item) => item !== vehicleId);
			return updatedVehicleIds;
		});
	}

	function handleDeleteExistingVehicle(vehicleId: number) {
		setDeletingVehicleIds((currentVehicleIds) => {
			const updatedVehicleIds = [...currentVehicleIds];
			updatedVehicleIds.push(vehicleId);
			return updatedVehicleIds;
		});
	}

	function handleChangeAddedVehicleType(index: number, updatedValue: number) {
		setAddedVehiclesList((currentVehiclesList) => {
			const updatedVehiclesList = [...currentVehiclesList];

			updatedVehiclesList[index] = {
				...updatedVehiclesList[index],
				vehicle_type: updatedValue
			};

			return updatedVehiclesList;
		});
	}

	function handleChangeAddedVehicleNumber(index: number, updatedValue: string) {
		setAddedVehiclesList((currentVehiclesList) => {
			const updatedVehiclesList = [...currentVehiclesList];

			updatedVehiclesList[index] = {
				...updatedVehiclesList[index],
				vehicle_number: updatedValue
			};

			return updatedVehiclesList;
		});
	}

	function handleRevertDeletedAddedVehicle(index: number) {
		setDeletingAddedVehicleIds((currentVehicleIds) => {
			const updatedVehicleIds = [...currentVehicleIds];
			updatedVehicleIds.splice(index, 1);
			return updatedVehicleIds;
		});
	}

	function handleDeleteAddedVehicle(index: number) {
		setDeletingAddedVehicleIds((currentVehicleIds) => {
			const updatedVehicleIds = [...currentVehicleIds];
			updatedVehicleIds.push(index);
			return updatedVehicleIds;
		});
	}

	async function handleSubmitForm(data: IFormFields) {
		const selectedOwnerTypeDetails = ownerTypesList.find((item) => item.id === Number(data.owner_type));

		if (memberDetails) {
			const formData: IEditVehicleOwnerVehiclesRequestData = {
				id: memberDetails.id,
				name: data.name ? convertToTitleCase(data.name) : null,
				phone_number: data.contact_number ? data.contact_number : null,
				owner_type: Number(data.owner_type),
				society: societyDetails ? societyDetails.id : 1,
				vehicles: [],
				flat_number: data.flat_no ? data.flat_no : null,
				is_custom_flat_number: customFlatSelectionEnabled,
				device: data.devices
			};

			// Number(data.owner_type) === memberOwnerTypeId ||

			// if (selectedOwnerTypeDetails && selectedOwnerTypeDetails.has_expiry) {
			// 	formData.flat_number = data.flat_no;
			// 	formData.is_custom_flat_number = customFlatSelectionEnabled;
			// }

			if (selectedOwnerTypeDetails && selectedOwnerTypeDetails.has_expiry) {
				formData.validity_start_date = moment(data.validity_start_date).format("YYYY-MM-DD");
				formData.validity_end_date = moment(data.validity_end_date).format("YYYY-MM-DD");
			}

			if (deletingVehicleIds.length > 0) {
				const response = await deleteVehicleOwnerVehicles(deletingVehicleIds);

				if ("error" in response && "data" in response.error) {
					setApiError(response.error.data ? String(response.error.data) : "Could not delete vehicles");
					return;
				}
			}

			for (const vehicleItem of existingVehiclesList) {
				if (deletingVehicleIds.includes(vehicleItem.id)) continue;

				formData.vehicles.push({
					id: vehicleItem.id,
					number_plate: vehicleItem.vehicle_number.toUpperCase(),
					vehicle_type: vehicleItem.vehicle_type,
					auth_type: "whitelist",
					society: societyDetails ? societyDetails.id : 1,
					owner: memberDetails.id
				});
			}

			const updatedVehicleErrors: IAddedVehicleErrors = {};

			for (const [index, vehicleItem] of Object.entries(addedVehiclesList)) {
				if (deletingAddedVehicleIds.includes(Number(index))) continue;

				if (
					excelValidations &&
					excelValidations.number_plate.regex &&
					!excelValidations.number_plate.regex.test(vehicleItem.vehicle_number.toUpperCase())
				) {
					updatedVehicleErrors[Number(index)] = "Invalid vehicle number";
					continue;
				}

				formData.vehicles.push({
					number_plate: vehicleItem.vehicle_number.toUpperCase(),
					vehicle_type: vehicleItem.vehicle_type,
					auth_type: "whitelist",
					society: societyDetails ? societyDetails.id : 1,
					owner: memberDetails.id
				});
			}

			setAddedVehiclesError(updatedVehicleErrors);

			if (Object.keys(updatedVehicleErrors).length <= 0) {
				editVehicleOwner(formData);
			}
		} else {
			const formData: ICreateVehicleOwnerVehiclesRequestData = {
				name: data.name ? data.name : null,
				phone_number: data.contact_number ? data.contact_number : null,
				owner_type: Number(data.owner_type),
				society: societyDetails ? societyDetails.id : 1,
				vehicles: [],
				flat_number: data.flat_no ? data.flat_no : null,
				is_custom_flat_number: customFlatSelectionEnabled,
				device: data.devices
			};

			// formData.owner_type === memberOwnerTypeId ||

			// if (selectedOwnerTypeDetails && selectedOwnerTypeDetails.has_expiry) {
			// 	formData.flat_number = data.flat_no;
			// 	formData.is_custom_flat_number = customFlatSelectionEnabled;
			// }

			if (selectedOwnerTypeDetails && selectedOwnerTypeDetails.has_expiry) {
				formData.validity_start_date = moment(data.validity_start_date).format("YYYY-MM-DD");
				formData.validity_end_date = moment(data.validity_end_date).format("YYYY-MM-DD");
			}

			const updatedVehicleErrors: IAddedVehicleErrors = {};

			for (const [index, vehicleItem] of Object.entries(addedVehiclesList)) {
				if (deletingAddedVehicleIds.includes(Number(index))) continue;

				if (
					excelValidations &&
					excelValidations.number_plate.regex &&
					!excelValidations.number_plate.regex.test(vehicleItem.vehicle_number.toUpperCase())
				) {
					updatedVehicleErrors[Number(index)] = "Invalid vehicle number";
					continue;
				}

				formData.vehicles.push({
					number_plate: vehicleItem.vehicle_number.toUpperCase(),
					vehicle_type: vehicleItem.vehicle_type,
					auth_type: "whitelist",
					society: societyDetails ? societyDetails.id : 1
				});
			}

			setAddedVehiclesError(updatedVehicleErrors);

			if (Object.keys(updatedVehicleErrors).length <= 0) {
				addVehicleOwner(formData);
			}
		}
	}

	function handleChangeCustomFlatSelectionEnabled(isChecked: boolean) {
		setCustomFlatSelectionEnabled(isChecked);
	}

	function getValidationError(
		errorKey: keyof IFormFields,
		type: "required" | "pattern" | "max_length",
		limit?: number
	): string {
		const requiredErrorTemplate = "{key} is required";
		const patternErrorTemplate = "Enter a valid {key}";
		const maxLengthErrorTemplate = `{key} should not be longer than ${limit ?? 0} characters`;

		let key = "";

		if (errorKey === "name") key = "name";
		if (errorKey === "contact_number") key = "contact number";
		if (errorKey === "flat_no") key = "unit number";

		if (type === "required") return requiredErrorTemplate.replace("{key}", convertToTitleCase(key));
		if (type === "pattern") return patternErrorTemplate.replace("{key}", key);
		if (type === "max_length") return maxLengthErrorTemplate.replace("{key}", convertToTitleCase(key));

		return "";
	}

	function getValidations<T extends keyof IFormFields>(key: T): RegisterOptions<IFormFields, T> {
		if (!excelValidations) return {};

		let validationOptions = null;

		if (key === "name") validationOptions = excelValidations.name;
		else if (key === "contact_number") validationOptions = excelValidations.phone_number;
		else if (key === "flat_no") validationOptions = excelValidations.flat_number;

		if (!validationOptions) return {};

		const validations: RegisterOptions = {};

		if (!validationOptions.allow_null) {
			validations.required = getValidationError(key, "required");
		}

		if (typeof validationOptions.char_size === "number") {
			validations.maxLength = {
				value: validationOptions.char_size,
				message: getValidationError(key, "max_length", validationOptions.char_size)
			};
		}

		if (validationOptions.regex) {
			validations.pattern = {
				value: validationOptions.regex,
				message: getValidationError(key, "pattern")
			};
		}

		return validations;
	}

	function handleOpenSecureLoginDialog() {
		dispatch(openSecureLoginDialog());
	}

	useEffect(() => {
		if (open) {
			if (memberDetails) {
				reset({
					name: memberDetails.name,
					flat_no: memberDetails.flat_no,
					contact_number: memberDetails.contact_number,
					owner_type: memberDetails.owner_type_id,
					validity_start_date: memberDetails.validity_start_date ? moment(memberDetails.validity_start_date) : null,
					validity_end_date: memberDetails.validity_end_date ? moment(memberDetails.validity_end_date) : null,
					devices: memberDetails.devices ?? []
				});

				setExistingVehiclesList(memberDetails.vehicles);
				setCustomFlatSelectionEnabled(memberDetails.is_custom_flat_number);
			} else {
				reset({
					name: "",
					flat_no: "",
					contact_number: "",
					owner_type: 0,
					validity_start_date: null,
					validity_end_date: null,
					devices: devicesList.map((item) => item.id)
				});

				setExistingVehiclesList([]);
				setCustomFlatSelectionEnabled(false);
			}
		} else {
			setAddedVehiclesList([]);
			setDeletingVehicleIds([]);
			setDeletingAddedVehicleIds([]);
			setAddedVehiclesError({});
			setExistingVehiclesError({});
			setShowValidityInputs(false);
			resetAddVehicleOwnerUpdatedState();
			resetEditVehicleOwnerState();
			setApiError("");

			// reset({
			// 	name: "",
			// 	flat_no: "",
			// 	contact_number: "",
			// 	owner_type: 0,
			// 	validity_start_date: null,
			// 	validity_end_date: null
			// });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, memberDetails, devicesList]);

	useEffect(() => {
		if (addVehicleOwnerSuccess) {
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addVehicleOwnerSuccess]);

	useEffect(() => {
		if (editVehicleOwnerSuccess) {
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editVehicleOwnerSuccess]);

	useEffect(() => {
		if (selectedOwnerType) {
			const selectedOwnerTypeDetails = ownerTypesList.find(
				(ownerTypeItem) => ownerTypeItem.id === Number(selectedOwnerType)
			);

			if (selectedOwnerTypeDetails && selectedOwnerTypeDetails.has_expiry) {
				setShowValidityInputs(true);
			} else {
				setShowValidityInputs(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOwnerType]);

	useEffect(() => {
		if (FlatsList.length > 0) {
			setCustomFlatSelectionEnabled(false);
		} else {
			setCustomFlatSelectionEnabled(true);
		}
	}, [FlatsList.length]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" color="white" classes={{ root: "dialog-title-root success" }}>
				Authorized List Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Grid container spacing={2} component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid item xs={12} md={6}>
						<InputLabel
							required
							error={!!errors.name}
							htmlFor="name-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Name
						</InputLabel>

						<TextField
							required
							fullWidth
							size="small"
							color="primary"
							variant="filled"
							id="name-input"
							placeholder="Enter the name of the person"
							error={!!errors.name}
							helperText={errors.name && errors.name.message ? errors.name.message.toString() : ""}
							InputProps={{
								disableUnderline: true,
								hiddenLabel: true,
								readOnly: ReadOnlyInput,
								classes: { root: "filled-input-root" }
							}}
							inputProps={{ style: { textTransform: "capitalize" } }}
							{...register("name", getValidations("name"))}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							required={excelValidations && !excelValidations.phone_number.allow_null}
							error={!!errors.contact_number}
							htmlFor="contact-number-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Contact Number
						</InputLabel>

						<NumberInput
							required={excelValidations && !excelValidations.phone_number.allow_null}
							fullWidth
							isContactNumber
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							color="primary"
							variant="filled"
							id="contact-number-input"
							placeholder="Enter the contact number"
							error={!!errors.contact_number}
							helperText={
								errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
							}
							InputProps={{
								disableUnderline: true,
								hiddenLabel: true,
								readOnly: ReadOnlyInput,
								classes: { root: "filled-input-root" }
							}}
							// {...register("contact_number", {
							// 	required:
							// 		excelValidations && !excelValidations.phone_number.allow_null ? "Contact number is required" : false,
							// 	pattern:
							// 		excelValidations && excelValidations.phone_number.regex
							// 			? { value: CONTACT_NUMBER_REGEX, message: "Enter a valid contact number" }
							// 			: undefined,
							// 	maxLength:
							// 		excelValidations && typeof excelValidations.phone_number.char_size === "number"
							// 			? { value: excelValidations.phone_number.char_size, message: "Enter a valid contact number" }
							// 			: undefined
							// })}
							{...register("contact_number", getValidations("contact_number"))}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name="owner_type"
							control={control}
							rules={{ min: { value: 1, message: "Owner Type is required" } }}
							render={({ field: { value, onChange } }) => (
								<>
									<InputLabel
										required
										error={!!errors.owner_type}
										htmlFor="authorized-list-owner-type-input"
										sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
									>
										Owner Type
									</InputLabel>

									<FormControl
										required
										fullWidth
										hiddenLabel
										size="small"
										variant="filled"
										id="authorized-list-owner-type-input"
										error={!!errors.owner_type}
									>
										<Select
											required
											fullWidth
											disableUnderline
											value={value}
											onChange={onChange}
											classes={{ root: "filled-input-root" }}
											readOnly={ReadOnlyInput}
										>
											{ownerTypesList
												? ownerTypesList.map((ownerTypeItem) => (
														<MenuItem value={ownerTypeItem.id} key={ownerTypeItem.id}>
															{ownerTypeItem.type_of}
														</MenuItem>
												  ))
												: null}
										</Select>

										{errors.owner_type && errors.owner_type.message ? (
											<FormHelperText>{errors.owner_type.message.toString()}</FormHelperText>
										) : null}
									</FormControl>
								</>
							)}
						/>
					</Grid>

					{/* {Number(selectedOwnerType) === memberOwnerTypeId || showValidityInputs ? ( */}
					<Grid item xs={12} md={6}>
						<InputLabel
							required
							error={!!errors.flat_no}
							htmlFor="flat-number-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Unit Number
						</InputLabel>

						{customFlatSelectionEnabled ? (
							<TextField
								required
								fullWidth
								size="small"
								color="primary"
								variant="filled"
								id="flat-number-input"
								placeholder="Enter the unit number"
								error={!!errors.flat_no}
								helperText={errors.flat_no && errors.flat_no.message ? errors.flat_no.message.toString() : ""}
								InputProps={{
									disableUnderline: true,
									hiddenLabel: true,
									readOnly: ReadOnlyInput,
									classes: { root: "filled-input-root" }
								}}
								{...register("flat_no", getValidations("flat_no"))}
							/>
						) : (
							<Controller
								name="flat_no"
								control={control}
								defaultValue=""
								rules={getValidations("flat_no")}
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={FlatsList}
										getOptionLabel={(option) => option.key}
										getOptionKey={(option) => option.key}
										groupBy={(option) => option.building}
										// sx={{ height: "100%" }}
										value={FlatsList.find((item) => item.key === value) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.key ?? null)}
										id="flat-number-input"
										readOnly={ReadOnlyInput}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												required
												hiddenLabel
												variant="filled"
												size="small"
												placeholder="Enter the unit number"
												sx={{ height: "100%" }}
												error={!!errors.flat_no}
												helperText={errors.flat_no && errors.flat_no.message ? errors.flat_no.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													disableUnderline: true,
													hiddenLabel: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						)}

						<FormControlLabel
							control={
								<Checkbox
									checked={customFlatSelectionEnabled}
									onChange={(_, checked) => handleChangeCustomFlatSelectionEnabled(checked)}
								/>
							}
							label="Custom Unit Number"
						/>
					</Grid>
					{/* ) : null} */}

					{showValidityInputs ? (
						<>
							<Grid item xs={12} md={6}>
								<Controller
									name="validity_start_date"
									control={control}
									rules={{ required: showValidityInputs ? "Start date is required" : false }}
									render={({ field: { value, onChange } }) => (
										<>
											<InputLabel
												required={showValidityInputs}
												error={!!errors.validity_start_date}
												htmlFor="validity-start-date-input"
												sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
											>
												Validity Start Date
											</InputLabel>

											<DatePicker
												format="DD/MM/YYYY"
												value={value}
												onChange={onChange}
												readOnly={ReadOnlyInput}
												slotProps={{
													textField: {
														required: true,
														fullWidth: true,
														size: "small",
														variant: "filled",
														color: "primary",
														id: "validity-start-date-input",
														error: !!errors.validity_start_date,
														helperText:
															errors.validity_start_date && errors.validity_start_date.message
																? errors.validity_start_date.message.toString()
																: "",
														InputProps: {
															disableUnderline: true,
															hiddenLabel: true,
															classes: { root: "filled-input-root" }
														}
													}
												}}
											/>
										</>
									)}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<Controller
									name="validity_end_date"
									control={control}
									rules={{
										required: showValidityInputs ? "End date is required" : false,
										validate: (value, formData) => {
											if (showValidityInputs) {
												if (moment(value).isBefore(moment(formData.validity_start_date), "day")) {
													return "End date should be after start date";
												}
											}

											return true;
										}
									}}
									render={({ field: { value, onChange } }) => (
										<>
											<InputLabel
												required={showValidityInputs}
												error={!!errors.validity_end_date}
												htmlFor="validity-end-date-input"
												sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
											>
												Validity End Date
											</InputLabel>

											<DatePicker
												format="DD/MM/YYYY"
												value={value}
												onChange={onChange}
												readOnly={ReadOnlyInput}
												slotProps={{
													textField: {
														required: true,
														fullWidth: true,
														size: "small",
														color: "primary",
														variant: "filled",
														id: "validity-end-date-input",
														error: !!errors.validity_end_date,
														helperText:
															errors.validity_end_date && errors.validity_end_date.message
																? errors.validity_end_date.message.toString()
																: "",
														InputProps: {
															disableUnderline: true,
															hiddenLabel: true,
															classes: { root: "filled-input-root" }
														}
													}
												}}
											/>
										</>
									)}
								/>
							</Grid>
						</>
					) : null}

					<Grid item xs={12} md={6}>
						<InputLabel
							error={!!errors.devices}
							htmlFor="devices-input"
							sx={{ color: "#424242", fontWeight: 500, marginBottom: "0.5rem" }}
						>
							Devices
						</InputLabel>

						<Controller
							control={control}
							name="devices"
							rules={{
								validate: (value) => {
									if (value.length <= 0) {
										return "At least one device should be selected";
									}

									return true;
								}
							}}
							render={({ field: { value, onChange } }) => (
								<>
									<Autocomplete
										fullWidth
										multiple
										options={devicesList}
										getOptionLabel={(option) => option.device_name}
										getOptionKey={(option) => option.id}
										id="devices-input"
										disableCloseOnSelect
										limitTags={1}
										value={devicesList.filter((item) => value.includes(item.id))}
										onChange={(_, updatedValue) => onChange(updatedValue.map((item) => item.id))}
										readOnly={ReadOnlyInput}
										renderOption={(props, option, { selected }) => (
											<li {...props} style={{ gap: "0.5rem" }}>
												{selected ? (
													<CheckBoxIcon color="primary" sx={{ marginY: "0.25rem" }} />
												) : (
													<CheckBoxOutlineBlankIcon color="primary" sx={{ marginY: "0.25rem" }} />
												)}

												{option.device_name}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												hiddenLabel
												variant="filled"
												size="small"
												placeholder="Select the devices"
												sx={{ height: "100%" }}
												error={!!errors.devices}
												helperText={errors.devices && errors.devices.message ? errors.devices.message.toString() : ""}
												InputProps={{
													...params.InputProps,
													disableUnderline: true,
													hiddenLabel: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
										renderTags={() => ""}
										PaperComponent={(props) => {
											const { children, ...restProps } = props;
											const AllDevicesSelected =
												value.length === devicesList.length ? "all" : value.length === 0 ? "none" : "partial";

											return (
												<Paper {...restProps}>
													<Box
														onMouseDown={(event) => event.preventDefault()}
														sx={{ paddingX: "1.25rem", paddingY: "0.25rem", width: "100%" }}
													>
														<FormControlLabel
															label="Select All"
															sx={{ width: "100%" }}
															control={
																<Checkbox
																	checked={AllDevicesSelected === "all"}
																	indeterminate={AllDevicesSelected === "partial"}
																/>
															}
															onClick={(event) => {
																event.preventDefault();
																if (AllDevicesSelected === "none" || AllDevicesSelected === "partial") {
																	onChange(devicesList.map((item) => item.id));
																} else if (AllDevicesSelected === "all") {
																	onChange([]);
																}
															}}
														/>
													</Box>

													<Divider />
													{children}
												</Paper>
											);
										}}
									/>

									<Typography variant="body2" sx={{ marginTop: "0.25rem", marginLeft: "0.25rem" }}>
										{value.length === 0
											? "No devices selected"
											: value.length === devicesList.length
											? "All devices selected"
											: `${value.length} device${value.length !== 1 ? "s" : ""} selected`}
									</Typography>
								</>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Box className="vehicles-list-wrapper">
							<Typography fontWeight={600}>Known list of Vehicles</Typography>

							{existingVehiclesList.map((vehicleItem, index) => (
								<Box className="vehicles-list-details-wrapper" key={index}>
									<Box className="vehicle-item-input-wrapper">
										<FormControl
											variant="standard"
											color="primary"
											fullWidth
											required
											disabled={deletingVehicleIds.includes(vehicleItem.id)}
										>
											<InputLabel id="authorized-list-vehicle-type-input-label">Vehicle Type</InputLabel>

											<Select
												required
												fullWidth
												label="Vehicle Type"
												labelId="authorized-list-vehicle-type-input-label"
												value={vehicleItem.vehicle_type}
												onChange={(event) => handleChangeExistingVehicleType(index, Number(event.target.value))}
												readOnly
											>
												{VEHICLE_TYPES_LIST.map((vehicleTypeItem) => (
													<MenuItem value={vehicleTypeItem.id} key={vehicleTypeItem.id}>
														{vehicleTypeItem.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Box>

									<Box className="vehicle-item-input-wrapper">
										<TextField
											required
											fullWidth
											disabled={deletingVehicleIds.includes(vehicleItem.id)}
											color="primary"
											variant="standard"
											label="Vehicle Number"
											value={vehicleItem.vehicle_number}
											onChange={(event) => handleChangeExistingVehicleNumber(index, event.target.value)}
											classes={{ root: "vehicle-number-input-root" }}
											InputProps={{ readOnly: true }}
											error={!!existingVehiclesError[vehicleItem.id]}
											helperText={existingVehiclesError ? existingVehiclesError[vehicleItem.id] : ""}
										/>
									</Box>

									<Box>
										{deletingVehicleIds.includes(vehicleItem.id) ? (
											<IconButton onClick={() => handleRevertDeletedExistingVehicle(vehicleItem.id)}>
												<CloseIcon />
											</IconButton>
										) : (
											<IconButton onClick={() => handleDeleteExistingVehicle(vehicleItem.id)}>
												<DeleteIcon />
											</IconButton>
										)}
									</Box>
								</Box>
							))}

							{addedVehiclesList.map((vehicleItem, index) => (
								<Box className="vehicles-list-details-wrapper" key={index}>
									<Box sx={{ flex: 1, display: "flex", gap: "1rem" }}>
										<Box className="vehicle-item-input-wrapper">
											<FormControl
												variant="standard"
												color="primary"
												fullWidth
												required
												disabled={deletingAddedVehicleIds.includes(index)}
											>
												<InputLabel id="authorized-list-vehicle-type-input-label">Vehicle Type</InputLabel>

												<Select
													required
													fullWidth
													label="Vehicle Type"
													labelId="authorized-list-vehicle-type-input-label"
													value={vehicleItem.vehicle_type}
													onChange={(event) => handleChangeAddedVehicleType(index, Number(event.target.value))}
												>
													{VEHICLE_TYPES_LIST.map((ownerTypeItem) => (
														<MenuItem value={ownerTypeItem.id} key={ownerTypeItem.id}>
															{ownerTypeItem.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Box>

										<Box className="vehicle-item-input-wrapper">
											<TextField
												required
												fullWidth
												disabled={deletingAddedVehicleIds.includes(index)}
												color="primary"
												variant="standard"
												label="Vehicle Number"
												value={vehicleItem.vehicle_number}
												onChange={(event) => handleChangeAddedVehicleNumber(index, event.target.value)}
												error={!!addedVehiclesError[index]}
												helperText={addedVehiclesError ? addedVehiclesError[index] : ""}
												classes={{ root: "vehicle-number-input-root" }}
											/>
										</Box>
									</Box>

									<Box>
										{deletingAddedVehicleIds.includes(index) ? (
											<IconButton onClick={() => handleRevertDeletedAddedVehicle(index)}>
												<CloseIcon />
											</IconButton>
										) : (
											<IconButton onClick={() => handleDeleteAddedVehicle(index)}>
												<DeleteIcon />
											</IconButton>
										)}
									</Box>
								</Box>
							))}

							{!ReadOnlyInput ? (
								<Box className="add-new-vehicle-button" onClick={handleAddNewVehicle}>
									<AddCircleIcon color="primary" />

									<Typography fontWeight={500} color="var(--color-primary-main)">
										Add new Vehicle
									</Typography>
								</Box>
							) : null}
						</Box>
					</Grid>

					{apiError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{apiError}
							</Typography>
						</Grid>
					) : null}

					{addVehicleOwnerError && "data" in addVehicleOwnerError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{addVehicleOwnerError.data ? String(addVehicleOwnerError.data) : "Something went wrong"}
							</Typography>
						</Grid>
					) : null}

					{editVehicleOwnerError && "data" in editVehicleOwnerError ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{editVehicleOwnerError.data ? String(editVehicleOwnerError.data) : "Something went wrong"}
							</Typography>
						</Grid>
					) : null}

					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
							<SecureLoginAccessButton
								tooltipAction={handleOpenSecureLoginDialog}
								variant="contained"
								color="primary"
								type="submit"
								sx={{ paddingX: 5 }}
								loading={memberDetails ? editVehicleOwnerLoading : addVehicleOwnerLoading}
							>
								Save
							</SecureLoginAccessButton>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AuthorizedMemberDetailsDialog;
