import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { FC, ReactNode, useRef, useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import BlockIcon from "@mui/icons-material/Block";

import "./logTypeSelection.css";
import { ReactComponent as UnauthorizedIcon } from "../../assets/icons/unauthorized.svg";
import { ReactComponent as VisitorIcon } from "../../assets/icons/visitor.svg";
import { LogFilterType } from "../../types";

interface ILogTypeSelectionProps {
	defaultValue?: LogFilterType;
	onChange: (updatedValue: LogFilterType | "") => void;
}

interface ILogTypeDetails {
	key: LogFilterType | "";
	name: string;
	icon: ReactNode;
}

const LOG_TYPES_LIST: ILogTypeDetails[] = [
	{
		key: "",
		name: "All Logs",
		icon: <GridViewIcon fontSize="small" color="inherit" />
	},
	{
		key: "authorized",
		name: "Authorized",
		icon: <PlaylistAddCheckIcon fontSize="small" color="inherit" />
	},
	{
		key: "blacklisted",
		name: "Blacklisted",
		icon: <BlockIcon fontSize="small" color="inherit" />
	},
	{
		key: "unauthorized",
		name: "Unauthorized",
		icon: <UnauthorizedIcon width={20} fill="inherit" />
	},
	{
		key: "visitor",
		name: "Visitor",
		icon: <VisitorIcon width={20} fill="inherit" />
	}
];

const LogTypeSelection: FC<ILogTypeSelectionProps> = (props) => {
	const { defaultValue, onChange } = props;

	const dropdownAnchorElementRef = useRef<HTMLDivElement>(null);

	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [selectedLogType, setSelectedLogType] = useState<ILogTypeDetails>(
		LOG_TYPES_LIST.find((item) => item.key === (defaultValue ?? "")) ?? LOG_TYPES_LIST[0]
	);

	function handleOpenDropdown() {
		setShowDropdown(true);
	}

	function handleCloseDropdown() {
		setShowDropdown(false);
	}

	function handleChangeSelectedLogType(updatedValue: ILogTypeDetails) {
		setSelectedLogType(updatedValue);
		handleCloseDropdown();
		onChange(updatedValue.key);
	}

	return (
		<>
			<Box
				className={`log-type-selection-button ${selectedLogType.key}`}
				component="div"
				ref={dropdownAnchorElementRef}
				aria-haspopup="true"
				id="log-type-selection-dropdown-button"
				onClick={handleOpenDropdown}
				aria-expanded={showDropdown}
				aria-controls={"log-type-selection-dropdown-menu"}
			>
				<Box className="log-type-selection-button-text">
					{selectedLogType.icon}

					<Typography color="inherit" fontSize={14} fontWeight={500}>
						{selectedLogType.name}
					</Typography>
				</Box>

				<ArrowDropDownIcon fontSize="small" color="inherit" />
			</Box>

			<Menu
				disableAutoFocusItem
				id="log-type-selection-dropdown-menu"
				anchorEl={dropdownAnchorElementRef.current}
				open={showDropdown}
				onClose={handleCloseDropdown}
				MenuListProps={{
					disablePadding: true,
					"aria-labelledby": "log-type-selection-dropdown-button",
					classes: { root: "log-type-selection-dropdown-menu" }
				}}
				slotProps={{ paper: { elevation: 1 } }}
			>
				{LOG_TYPES_LIST.map((logTypeItem) =>
					logTypeItem.key === selectedLogType.key ? null : (
						<MenuItem
							key={logTypeItem.key}
							onClick={() => handleChangeSelectedLogType(logTypeItem)}
							classes={{ root: `log-type-selection-dropdown-menu-item ${logTypeItem.key}` }}
						>
							<ListItemIcon classes={{ root: "log-type-selection-dropdown-menu-item-icon" }}>
								{logTypeItem.icon}
							</ListItemIcon>

							{logTypeItem.name}
						</MenuItem>
					)
				)}
			</Menu>
		</>
	);
};

export default LogTypeSelection;
