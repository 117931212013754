import { IExcelValidationDetails } from "../types";

export const ROWS_PER_PAGE = 20;

export const PERSON_NAME_REGEX = /^[a-z0-9 ,.'\-()/]+$/i;
export const CONTACT_NUMBER_REGEX = /^[6-9]{1}\d{9}$/;
export const FLAT_NUMBER_REGEX = /^[a-zA-Z0-9 ,*\-/]+$/;
export const NUMBER_PLATE_REGEX =
	/^([A-Za-z]{2}[0-9]{1,2}[A-Za-z]{1,3}[0-9]{2,4})|([0-9]{2}(bh|BH|bH|Bh)[0-9]{4}[A-Za-z]{1,2})|([A-Za-z]{2}[0-9]{6})|([A-Za-z]{3}[0-9]{4})/;

export const VEHICLE_TYPES_LIST = [
	{ id: 0, name: "Four Wheeler" },
	{ id: 1, name: "Two Wheeler" }
];

export enum VEHICLE_TYPE_E {
	FOUR_WHEELER = 0,
	TWO_WHEELER = 1
}

export const EVENT_LOGS_AUTO_REFRESH_INTERVAL = 60; // IN SECONDS
export const DASHBOARD_NOTIFICATIONS_AUTO_REFRESH_INTERVAL = 30; // IN SECONDS

// BUILDING CONFIG
export enum BUILDING_TYPES_E {
	BUILDING = 0,
	CUSTOM_ENTRIES = 1
}

export const CUSTOM_ENTRY_BUILDING_NAME = "Common Area";

export const MEMBER_OWNER_TYPE_NAME = "Member";
export const TENANT_OWNER_TYPE_NAME = "Tenant";

export const TIME_FILTER_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const TIME_FILTER_URL_FORMAT = "YYYY-MM-DD_HH-mm-ss";

export enum CONFIG_MODE_E {
	GATE_GUARD = 0,
	ANPR = 1,
	MY_GATE = 3
}

export const DEFAULT_VALIDATIONS: IExcelValidationDetails = {
	name: {
		type: "char",
		strip: true,
		remove_whitespace: false,
		regex: PERSON_NAME_REGEX,
		capitalize: false,
		title: true,
		lower: false,
		char_size: 160,
		allow_null: false
	},
	number_plate: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: NUMBER_PLATE_REGEX,
		capitalize: true,
		title: true,
		lower: false,
		char_size: 17,
		allow_null: false
	},
	flat_number: {
		type: "char/dropdown",
		strip: true,
		remove_whitespace: false,
		regex: FLAT_NUMBER_REGEX,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 160,
		allow_null: false
	},
	start_date: {
		type: "date",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	end_date: {
		type: "date",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	vehicle_type: {
		type: "dropdown",
		strip: true,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	phone_number: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: CONTACT_NUMBER_REGEX,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 14,
		allow_null: false
	},
	auth_type: {
		type: "char",
		strip: true,
		remove_whitespace: true,
		regex: null,
		capitalize: false,
		title: false,
		lower: true,
		char_size: 150,
		allow_null: false
	},
	comment: {
		type: "char",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: 1000,
		allow_null: false
	},
	is_custom_flat_number: {
		type: "bool",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	},
	is_valid: {
		type: "bool",
		strip: false,
		remove_whitespace: false,
		regex: null,
		capitalize: false,
		title: false,
		lower: false,
		char_size: null,
		allow_null: false
	}
};
