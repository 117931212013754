import { Box, Button, ButtonOwnProps } from "@mui/material";
import React, { FC, ReactNode, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import BlockIcon from "@mui/icons-material/Block";
import ConstructionIcon from "@mui/icons-material/Construction";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import "./nestedPageLayout.css";
import { useAppSelector } from "../../redux";
import { CONFIG_MODE_E } from "../../utils/constants";

const DATA_MANAGEMENT_PATH_KEY = "data-management";
const CONFIGURATION_PATH_KEY = "configuration";

interface ITabButtonDetails {
	key: string;
	name: string;
	icon: ReactNode;
	color?: ButtonOwnProps["color"];
}

const DATA_MANAGEMENT_TABS_LIST: ITabButtonDetails[] = [
	{
		key: "authorized-list",
		name: "Authorized List",
		icon: <PlaylistAddCheckIcon />,
		color: "success"
	},
	{
		key: "blacklist",
		name: "Blacklist",
		icon: <BlockIcon />,
		color: "error"
	}
];

const CONFIGURATION_TABS_LIST: ITabButtonDetails[] = [
	{
		key: "device-and-camera-settings",
		name: "Device and Camera Settings",
		icon: <ConstructionIcon />
	},
	{
		key: "property-configuration",
		name: "Property Configuration",
		icon: <ApartmentIcon />
	},
	{
		key: "reporting",
		name: "Reporting",
		icon: <EmailIcon />
	},
	{
		key: "custom-fields",
		name: "Custom Fields",
		icon: <DashboardCustomizeIcon />
	}
];

const NestedPageLayout: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const authState = useAppSelector((state) => state.auth);

	const locationSplit = location.pathname.split("/");

	function handleChangeTab(newTab: string) {
		if (locationSplit[1] === DATA_MANAGEMENT_PATH_KEY) {
			navigate(`/${DATA_MANAGEMENT_PATH_KEY}/${newTab}`, { replace: true });
		} else if (locationSplit[1] === CONFIGURATION_PATH_KEY) {
			navigate(`/${CONFIGURATION_PATH_KEY}/${newTab}`, { replace: true });
		}
	}

	function getTabsList(): ITabButtonDetails[] {
		if (locationSplit[1] === DATA_MANAGEMENT_PATH_KEY) return DATA_MANAGEMENT_TABS_LIST;

		if (locationSplit[1] === CONFIGURATION_PATH_KEY) {
			if (authState.user.mode === CONFIG_MODE_E.MY_GATE) {
				return CONFIGURATION_TABS_LIST.filter((item) => item.key !== "property-configuration");
			}

			return CONFIGURATION_TABS_LIST;
		}
		return [];
	}

	useEffect(() => {
		if (authState.user.mode === CONFIG_MODE_E.MY_GATE) {
			if (locationSplit[1] === DATA_MANAGEMENT_PATH_KEY) {
				navigate("/dashboard", { replace: true });
			} else if (locationSplit[1] === CONFIGURATION_PATH_KEY) {
				navigate(`/${CONFIGURATION_PATH_KEY}/${CONFIGURATION_TABS_LIST[0].key}`, { replace: true });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.user.mode, locationSplit[1], navigate]);

	const activeTab = locationSplit[locationSplit.length - 1];

	return (
		<Box className="nested-page-layout-wrapper">
			{getTabsList().findIndex((tabItem) => tabItem.key === activeTab) >= 0 ? (
				<Box className="nested-page-layout-header">
					{getTabsList().map((tabItem) => (
						<Button
							key={tabItem.key}
							disableElevation
							variant={activeTab === tabItem.key ? "contained" : "outlined"}
							color={tabItem.color ? tabItem.color : "primary"}
							startIcon={tabItem.icon}
							onClick={() => handleChangeTab(tabItem.key)}
						>
							{tabItem.name}
						</Button>
					))}
				</Box>
			) : null}

			<Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
				<Outlet />
			</Box>
		</Box>
	);
};

export default NestedPageLayout;
