import { Box, IconButton, InputAdornment, TablePaginationProps, TextField } from "@mui/material";
import {
	gridFilteredTopLevelRowCountSelector,
	gridPageSizeSelector,
	useGridApiContext,
	useGridRootProps,
	useGridSelector
} from "@mui/x-data-grid";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, useState } from "react";
import MuiPagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";

const getPageCount = (rowCount: number, pageSize: number): number => {
	if (pageSize > 0 && rowCount > 0) {
		return Math.ceil(rowCount / pageSize);
	}
	return 0;
};

const TablePagination: FC<TablePaginationProps> = (props) => {
	const { page, onPageChange, className } = props;

	const [pageNumberInput, setPageNumberInput] = useState<string>("");

	const apiRef = useGridApiContext();
	const rootProps = useGridRootProps();

	const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
	const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
	const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);

	function handleChangePageNumberInput(event: ChangeEvent<HTMLInputElement>) {
		setPageNumberInput(event.target.value);
	}

	function handleSkipToPage(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (pageNumberInput) {
			onPageChange(null, Number(pageNumberInput) - 1);
			setPageNumberInput("");
		}
	}

	return (
		<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem" }}>
			<MuiPagination
				color="primary"
				className={className}
				count={pageCount}
				page={page + 1}
				onChange={(event, newPage) => onPageChange(event as MouseEvent<HTMLButtonElement>, newPage - 1)}
			/>

			<Box sx={{ width: "200px" }} component="form" noValidate onSubmit={handleSkipToPage}>
				<TextField
					fullWidth
					placeholder="Page"
					size="small"
					autoComplete="new-passwword"
					value={pageNumberInput}
					onChange={handleChangePageNumberInput}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end" type="submit">
									<SearchIcon fontSize="small" />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Box>
		</Box>
	);
};

export default TablePagination;
